import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { DefaultViewModel } from "../../core/BaseViewModel";
import { ModuleBuilder } from "../../core/ModuleBuilder/ModuleBuilder";
import { TPageComponent } from "../../core/types";

export enum RedirectTypes {
  auth,
  role,
}

type LocationState = {
  from?: string;
  type?: RedirectTypes;
  homePath?: string;
};

export const Authenticator: TPageComponent = ({ model, homePath }) => {
  const location = useLocation<LocationState>();
  const { isAuthenticated, isLoading } = model;

  const { from, type } = location.state ?? {};

  const redirectPath =
    from && type !== RedirectTypes.auth ? from : homePath ?? "/";

  return isLoading ? (
    <LoadingIndicator size={60} />
  ) : isAuthenticated ? (
    <Redirect to={redirectPath} />
  ) : null;
};

export default ModuleBuilder.configure(Authenticator, {
  model: () => new DefaultViewModel(),
  route: { path: "/", exact: true },
});
