import React, { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BaseViewModel } from "../core/BaseViewModel";
import { PageRenderer } from "../core/PageRenderer/PageRenderer";
import copyParams from "../helpers/copyParams";

export interface IHocProps<M = any> {
  children?: ReactNode;
  model: M;
  [key: string]: any;
}

export type IComponent = {
  (props: IHocProps): JSX.Element;
  [key: string]: any;
};

export interface IwithModelProps {
  model: BaseViewModel;
  loaderSelector?: string;
  component: IComponent;
}

export type IwithModel<T = IComponent> = (props: IwithModelProps) => T;

type THoc = (props: IHocProps) => JSX.Element;

export const withModel: IwithModel = ({
  model,
  component: Component,
  loaderSelector,
}) => {
  const Hoc: THoc = ({ children, ...props }) => {
    const params = useParams<{ [key: string]: string; id: string }>();

    const { id } = params;

    useEffect(() => {
      model.onInit?.(params);
      return () => model.onDestroy?.();
    }, [id]);

    return (
      <PageRenderer model={model} loaderSelector={loaderSelector ?? ""}>
        <Component {...props} model={model}>
          {children}
        </Component>
      </PageRenderer>
    );
  };

  return copyParams(Hoc, Component);
};
