import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FC } from "react";

type LoadingIndicatorProps = {
  size?: number;
  className?: string;
};

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  size,
  className,
}) => {
  const indicatorSize = size || 24;
  const antIcon = <LoadingOutlined style={{ fontSize: indicatorSize }} spin />;

  return <Spin className={className} indicator={antIcon} />;
};
