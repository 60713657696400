import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import "./FiltersSection.less";
import { GetPhrase } from "../../../hocs/WithTranslation";
import { ExpandedFiltersSection } from "../ExpandedFiltersSection/ExpandedFiltersSection";
import { Space } from "antd";
import { FiltersTopBar } from "../FiltersTopBar/FiltersTopBar";

interface FiltersSectionProps {
  model: any;
  translate: GetPhrase;
  currentLang: string;
  filterLabels: { [key: string]: string };
  getFilters: (model?: any, translate?: GetPhrase) => any[];
  children?: (props: TopBarProps) => React.ReactNode;
}

export type TopBarProps = Omit<FiltersSectionProps, "getFilters"> & {
  toggleIsFilterSectionExpanded: MouseEventHandler<HTMLElement>;
  isFilterSectionExpanded: boolean;
};

export const FiltersSection: React.FC<FiltersSectionProps> = observer(
  ({ model, translate, currentLang, getFilters, filterLabels, children }) => {
    const [isFilterSectionExpanded, setIsFilterSectionExpanded] =
      useState(false);

    const toggleIsFilterSectionExpanded = useCallback(
      () =>
        setIsFilterSectionExpanded(
          (isFilterSectionExpanded) => !isFilterSectionExpanded
        ),
      []
    );

    const topBarProps: TopBarProps = useMemo(
      () => ({
        model,
        translate,
        currentLang,
        isFilterSectionExpanded,
        toggleIsFilterSectionExpanded,
        filterLabels,
      }),
      [currentLang, isFilterSectionExpanded]
    );

    return (
      <Space className="filters-wrapper" size="large" direction="vertical">
        {children?.(topBarProps) ?? <FiltersTopBar {...topBarProps} />}
        {isFilterSectionExpanded && (
          <ExpandedFiltersSection
            filterLabels={filterLabels}
            model={model}
            translate={translate}
            getFilters={getFilters}
          />
        )}
      </Space>
    );
  }
);
