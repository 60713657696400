import { Callback } from "i18next";
import React, { ReactNode } from "react";
import { useTranslation, TFunction } from "react-i18next";
import copyParams from "../helpers/copyParams";

export interface IHocProps {
  children?: ReactNode;
  [key: string]: any;
}

export type GetPhrase = (key: string, callback?: () => any) => string;

export type ChangeLang = (langKey: string, callback?: () => any) => any;

export type TranslationPropsType = {
  translate: TFunction;
  currentLang: string;
  changeLang: (lng?: string, callback?: Callback) => Promise<TFunction>;
  defaultLang: string;
};

export type IComponent = {
  (props: IHocProps): JSX.Element;
  [key: string]: any;
};

export interface IwithTranslationProps<T> {
  component: T;
}

export type IwithTranslation<T = IComponent> = (
  props: IwithTranslationProps<T>
) => IComponent;

export const withTranslation: IwithTranslation = ({ component: Component }) => {
  const Hoc: IComponent = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();
    const translationProps: TranslationPropsType = {
      translate: t,
      currentLang: i18n.language,
      changeLang: i18n.changeLanguage.bind(i18n),
      defaultLang: i18n.languages[0],
    };
    return (
      <Component {...props} translation={translationProps}>
        {children}
      </Component>
    );
  };

  return copyParams(Hoc, Component);
};
