import { ColumnsState } from "@ant-design/pro-table";
import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  reaction,
  runInAction,
} from "mobx";
import { isNotNullish, isObject } from "../../helpers/helpers";
import storages, { LocalStorageKeys } from "../../services/Storage/Storage";

import {
  LOCAL_STORAGE_KEYS,
  ORGANIZATION_SPECIFIC_LOCATION_STORAGE_KEYS,
  PAGE_SPECIFIC_LOCAL_STORAGE_KEYS,
} from "./localStorageKeys";

export type TableColumnsType = {
  [key: string]: Record<string, ColumnsState>;
};

export class LocalStorageSynchronizer {
  reactionsDisposers: IReactionDisposer[] = [];
  model: any;
  keys: Array<string>;
  initDisposer?: IReactionDisposer;
  constructor(model: any, keys?: string[]) {
    this.model = model;
    this.keys = keys ?? Object.values(LOCAL_STORAGE_KEYS);
    makeObservable(this, {
      setObservedValues: action.bound,
    });
  }

  setObservedValues(data = {}): void {
    isObject(data) &&
      runInAction(() =>
        Object.entries(data).forEach(([key, value]) =>
          this.setModelParam(key, value)
        )
      );
  }

  init(user: { isAuthenticated: boolean }, callback?: () => any): void {
    this.setObservedValues(this.userStore.activeUserData);
    this.initDisposer = autorun(() => {
      if (user.isAuthenticated) {
        this.activateStorageReactions();
        callback?.();
      }
    });
    user.isAuthenticated && this.initDisposer?.();
  }

  destroy(callback?: () => any): void {
    this.disposeReactions();
    this.initDisposer?.();
    callback?.();
  }

  private setModelParam(key: string, value: any): void {
    if (key in this.model && isNotNullish(value)) {
      const { title, organizationName } = this.model;

      const organizationFilters = value[title]?.[organizationName] ?? {};

      this.model[key] = this.checkIfKeyIsPageKey(key, title)
        ? {
            [title]: this.checkIfKeyIsOrganizationKey(key, organizationName)
              ? { [organizationName]: organizationFilters }
              : value[title],
          }
        : value;
    }
  }

  private checkIfKeyIsPageKey(key: string, title?: string) {
    const isPageKey = Object.values(PAGE_SPECIFIC_LOCAL_STORAGE_KEYS).includes(
      key as any
    );
    return !!(isPageKey && title);
  }

  private checkIfKeyIsOrganizationKey(key: string, organization?: string) {
    const isOrganizationKey = Object.values(
      ORGANIZATION_SPECIFIC_LOCATION_STORAGE_KEYS
    ).includes(key as any);
    return !!(isOrganizationKey && organization);
  }

  private activateStorageReactions(): void {
    this.keys.forEach((key) => {
      const disposer = reaction(
        () => this.model[key],
        () => this.updateLocalStorage(key)
      );
      this.reactionsDisposers.push(disposer);
    });
  }

  private disposeReactions(): void {
    this.reactionsDisposers.forEach((disposer) => disposer());
    this.reactionsDisposers = [];
  }

  private updateLocalStorage(key: string): void {
    const { activeUser, usersData, activeUserData } = this.userStore;
    const newUsersData = {
      ...usersData,
      [activeUser]: this.getNewStorage(key, activeUserData),
    };

    storages.local.set({ users: newUsersData });
  }

  private getNewStorage(key: string, data: { [key: string]: any }) {
    return this.checkIfKeyIsPageKey(key, this.model.title)
      ? this.checkIfKeyIsOrganizationKey(key, this.model.organizationName)
        ? this.getNewOrganizationStorage(key, data)
        : this.getNewPageStorage(key, data)
      : this.getNewUserStorage(key, data);
  }

  private getNewOrganizationStorage(key: string, data: { [key: string]: any }) {
    return {
      ...data,
      [key]: {
        ...data[key],
        [this.model.title]: {
          ...(data[key]?.[this.model.title] ?? {}),
          ...this.model[key][this.model.title],
        },
      },
    };
  }

  private getNewPageStorage(key: string, data: { [key: string]: any }) {
    return {
      ...data,
      [key]: { ...data[key], ...this.model[key] },
    };
  }

  private getNewUserStorage(key: string, data: { [key: string]: any }) {
    return {
      ...data,
      [key]: this.model[key],
    };
  }

  private get userStore() {
    const usersData = storages.local.get(LocalStorageKeys.users);
    const activeUser = storages.local.get(LocalStorageKeys.activeUser);
    const activeUserData = usersData?.[activeUser] ?? {};
    return { usersData, activeUser, activeUserData };
  }
}
