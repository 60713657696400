import { useLocation } from "react-router-dom";
import {
  CustomLinkType,
  GetAddRouteType,
  GetEditRouteType,
  GetLink,
  GetPathType,
  MatchLink,
  Route,
  SetLink,
  SetPath,
} from "../core/types";

export const LINK_PREFIX = "v2/#";

export const setPath: SetPath = function _setPath(options) {
  this.isImplemented = !!options?.isImplemented;
  return options?.action
    ? `${this.path}/${options.action}${
        options?.paramName ? `/:${options.paramName}` : ""
      }`
    : this.path;
};

export const objectFromQuery = (
  queryString: string
): { [key: string]: string } =>
  Object.fromEntries(
    queryString
      .replace("?", "")
      .split("&")
      .map((query) => query.split("="))
  );

export const usePageTypeMatch = (pageTypeId: number): void => {
  const location = useLocation();
  const { typeId } = objectFromQuery(location.search);
  pageTypeId !== Number(typeId) &&
    window.location.replace(
      `${window.location.origin}${location.pathname}${location.search}`
    );
};

export const setLink: SetLink = function _setLink(options) {
  const { action, param, query, key } = options ?? {};
  this.link[key ?? "default"] = mergePath(this.path, action, param, query);
  return this;
};

export const getLink: GetLink = function _getLink(key, options) {
  const { param, query, action, fromPlain } = options || {};
  const path = `${this.link[String(key)] ?? this.link.default}`;

  return {
    isImplemented: this.isImplemented,
    path: fromPlain ? mergePath(path, action, param, query) : path,
  };
};

export const matchLink: MatchLink = function _matchLink(link, options) {
  return !!options?.isImplemented && !!options?.isExternal
    ? link.replace("/", options?.prefix ?? LINK_PREFIX)
    : link;
};

function mergePath(
  path: string,
  action?: string,
  param?: string | number | boolean,
  query?: { [key: string]: string | number | boolean }
): string {
  return `${
    path + addParam(action) + addParam(param) + queryFromObject(query)
  }`;
}

export function queryFromObject(query?: { [key: string]: any }) {
  return query
    ? Object.entries(query).reduce(
        (queryString, [key, value], index) =>
          (queryString += `${index === 0 ? "?" : "&"}${key}=${value}`),
        ""
      )
    : "";
}

function addParam(param?: string | boolean | number) {
  return param ? `/${param}` : "";
}

export function getCustomLinks(
  allLinks: any[],
  defaultCustomLinks: any[],
  savedLinks: CustomLinkType[],
  setCustomLinks: (customLinks: CustomLinkType[]) => void
): any[] {
  if (savedLinks.length) {
    return savedLinks;
  }
  const customLinks = allLinks.filter((link) => {
    return defaultCustomLinks?.includes(link.route.getLink(link.title).path);
  });
  setCustomLinks(customLinks);
  return customLinks;
}

export function handlePopState(options: {
  getPath: () => string;
  setPath?: (path: string) => void;
  setKeys?: (routes: any) => void;
  onChange?: (path: string, routeKeys: string[]) => void;
  routes: any;
}) {
  const onPopStateChange = (e: any) => {
    const path = options.getPath();
    options.onChange?.(path, options.routes[path]);
  };
  window.addEventListener("popstate", onPopStateChange);
  return () => window.removeEventListener("popstate", onPopStateChange);
}

export const getEditRoute: GetEditRouteType = (id: number, route: Route) =>
  route.getLink(undefined, { action: "Edit", param: id, fromPlain: true }).path;

export const getAddRoute: GetAddRouteType = (route: Route) =>
  route.getLink(undefined, { action: "Add", fromPlain: true }).path;

export const getPath: GetPathType = ({
  name,
  route,
}: {
  name?: string;
  route: Route;
}) =>
  route.setPath(name ? { action: "Edit", paramName: name } : { action: "Add" });

export function createRoute(path: string, isImplemented = false): Route {
  return {
    isImplemented,
    path: path,
    link: { default: path },
    setPath: setPath,
    setLink: setLink,
    getLink: getLink,
  };
}
