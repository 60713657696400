import React, { FC } from "react";
import { Divider, Layout } from "antd";
import classNames from "classnames";

import "./CustomLayout.less";

type CustomLayoutProps = {
  title?: string;
  className?: string;
  headerClassName?: string;
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerBottom?: React.ReactNode;
  withDivider?: boolean;
};

const CustomLayout: FC<CustomLayoutProps> = ({
  title,
  className,
  children,
  headerRight,
  headerLeft,
  headerBottom,
  withDivider,
  headerClassName,
}) => {
  return (
    <Layout className={classNames("layout", className ?? "")}>
      <Layout.Header className="page-header-wrapper">
        <div className={classNames("page-header-container", headerClassName)}>
          {headerLeft || <div />}
          {title}
          {headerRight || <div />}
        </div>
        {withDivider && <Divider className="divider" />}
        {headerBottom}
      </Layout.Header>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default CustomLayout;
