import { Input, Space } from "antd";
import React, { ChangeEvent, ReactNode } from "react";

interface CustomInputProps {
  label?: ReactNode;
  placeholder?: string;
  className?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  allowClear?: boolean;
  direction?: "vertical" | "horizontal";
  wrapperClassName?: string;
  maxLength?: number;
  size?: "small" | "middle" | "large";
  disabled?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
  addonAfter?: string;
  type?: string;
  defaultValue?: string;
}

export type OnChangeType = (value: ChangeEvent<HTMLInputElement>) => void;

export const CustomInput: React.FC<CustomInputProps> = ({
  label,
  placeholder,
  className,
  onChange,
  value,
  allowClear,
  direction,
  wrapperClassName,
  maxLength,
  size,
  disabled,
  onBlur,
  autoFocus,
  addonAfter,
  type,
  defaultValue
}) => {
  return (
    <Space direction={direction} className={wrapperClassName}>
      {label}
      <Input
        autoFocus={autoFocus}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        className={className}
        placeholder={placeholder}
        allowClear={allowClear}
        size={size}
        disabled={disabled}
        onBlur={onBlur}
        addonAfter={addonAfter}
        type={type}
        defaultValue={defaultValue}
      />
    </Space>
  );
};
