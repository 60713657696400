import Dinero, { Currency, RoundingMode } from "dinero.js";
import { action, makeObservable, observable } from "mobx";

type Config = {
  currency?: Currency;
  format?: string;
  roundingMode?: RoundingMode;
  withComma?: boolean;
  precision?: number;
};

export default class MonetaryFormater {
  currency: Currency;
  roundingMode?: RoundingMode;
  private formatPattern: string;
  private withComma?: boolean;
  private precision: number;
  constructor(
    {
      currency = "PLN",
      format = "0.00",
      roundingMode,
      withComma = true,
      precision = 2,
    }: Config = {} as Config
  ) {
    this.currency = currency;
    this.formatPattern = format;
    this.roundingMode = roundingMode;
    this.withComma = withComma;
    this.precision = precision;
    makeObservable(this, {
      currency: observable,
      roundingMode: observable,
      setCurrency: action.bound,
      setRoundingMode: action.bound,
    });
  }

  setCurrency(currency: Currency): Currency {
    return (this.currency = currency);
  }
  setRoundingMode(roundingMode: RoundingMode): RoundingMode {
    return (this.roundingMode = roundingMode);
  }

  normalize(price: number, options?: { precision?: number }): Dinero.Dinero {
    const precision = options?.precision ?? this.precision;

    return Dinero({
      amount: Number(((price * 10000) / 10 ** precision).toFixed()),
      precision,
    });
  }

  format(
    price: Dinero.Dinero,
    options?: { withCurrency?: boolean; withComma?: boolean }
  ): string {
    const formated = price
      .toFormat(this.formatPattern)
      .concat(options?.withCurrency ? this.currency : "");
    return options?.withComma || this.withComma
      ? formated.replace(".", ",")
      : formated;
  }
}
