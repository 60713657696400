export enum PAGE_SPECIFIC_LOCAL_STORAGE_KEYS {
  TABLE_COLUMNS = "tableColumns",
  FILTERS_GROUPS_LIST = "filtersGroupsList"
}

export enum USER_SPECIFIC_LOCAL_STORAGE_KEYS {
  IS_MENU_COLLAPSED = "isMenuCollapsed",
  CUSTOM_LINKS = "customLinks"
}

export enum ORGANIZATION_SPECIFIC_LOCATION_STORAGE_KEYS {
  FILTERS_GROUPS_LIST = "filtersGroupsList"
}

export const LOCAL_STORAGE_KEYS = {
  ...USER_SPECIFIC_LOCAL_STORAGE_KEYS,
  ...PAGE_SPECIFIC_LOCAL_STORAGE_KEYS,
  ...ORGANIZATION_SPECIFIC_LOCATION_STORAGE_KEYS
};
