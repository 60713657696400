type Models = { [key: number]: any };

export interface IModelProvider {
  get(key: number): any;
  add<M>(models: { [key: number]: M }): this;
  getAll(): Models;
}

export default abstract class ModelsProvider implements IModelProvider {
  protected models: Models = {};

  get(key: number): any {
    return this.models[key];
  }

  add<M>(models: { [key: number]: M }): this {
    for (const key in models) {
      this.models[key] = models[key];
    }
    return this;
  }

  getAll(): Models {
    return this.models;
  }
}
