import { makeObservable, observable, runInAction } from "mobx";
import { globalModels, MODELS_KEYS } from "../models/global";

export abstract class BaseViewModel {
  isInitialized = false;
  user = globalModels.get(MODELS_KEYS.user);
  constructor(public title: string = "") {
    makeObservable(this, { isInitialized: observable });
  }

  public onInit(params: Record<string, unknown>, callback?: () => any): void {
    this.changeIsInit(true);
    this._onInit(params, callback);
    return;
  }

  public onDestroy(callback?: () => any): void {
    this.changeIsInit(false);
    this._onDestroy(callback);
    return;
  }

  protected _onInit(
    params?: Record<string, unknown>,
    callback?: () => any
  ): void {
    return;
  }

  protected _onDestroy(callback?: () => any): void {
    return;
  }

  private changeIsInit(isInit: boolean) {
    runInAction(() => (this.isInitialized = isInit));
  }
}

export class DefaultViewModel extends BaseViewModel {
  constructor() {
    super();
  }

  get isAuthenticated() {
    return this.user.isAuthenticated;
  }

  get isLoading() {
    return this.user.isLoading;
  }
}
