import React, { FC, ReactNode, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Button, message, Space } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { GetPhrase } from "../../hocs/WithTranslation";
import { ConfirmDeleteModal } from "../ConfirmDeleteModal/ConfirmDeleteModal";
import { observer } from "mobx-react-lite";
import { Route } from "../../core/types";
import { CLOSE_MODAL_DELAY } from "../../core/globalConstants";
import { getEditRoute } from "../../helpers/routesHelpers";

import "./TableButtons.less";

type TableButtonsProps = {
  id: number;
  translate: GetPhrase;
  hasCopyButton?: boolean;
  copyPath?: string;
  hasEditButton?: boolean;
  editPath?: string;
  hasArchiveButton?: boolean;
  archivePath?: string;
  unarchivePath?: string;
  isArchival?: boolean;
  hasRemoveButton?: boolean;
  removePath?: string;
  route: Route;
  handleDelete?: (id: number) => boolean | Promise<boolean>;
  onDeleteSuccessMessage?: string;
  onDeleteFailMessage?: string;
  customButton?: ReactNode;
  phrases?: {
    edit?: string;
    copy?: string;
    delete?: string;
    archive?: string;
    unarchive?: string;
  };
};

export const TableButtons: React.FC<TableButtonsProps> = observer(
  ({
    translate,
    hasCopyButton,
    hasEditButton,
    hasArchiveButton,
    isArchival,
    hasRemoveButton,
    id,
    route,
    handleDelete,
    onDeleteSuccessMessage,
    onDeleteFailMessage,
    customButton,
    phrases,
  }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleConfirm = useCallback(async () => {
      const isSuccess = await handleDelete?.(id);
      isSuccess
        ? message.success(translate(onDeleteSuccessMessage ?? ""))
        : message.error(translate(onDeleteFailMessage ?? ""));
      setTimeout(() => setIsModalVisible(false), CLOSE_MODAL_DELAY);
    }, [
      id,
      handleDelete,
      onDeleteSuccessMessage,
      onDeleteFailMessage,
      phrases,
    ]);

    return (
      <>
        <Space className="table-buttons-container" size="small">
          {hasEditButton && (
            <Link to={getEditRoute(id, route) ?? ""}>
              <Button
                icon={<EditOutlined />}
                size="small"
                className="edit-button"
              >
                {translate(phrases?.edit ?? "")}
              </Button>
            </Link>
          )}
          {hasCopyButton && (
            <Button
              icon={<CopyOutlined />}
              size="small"
              className="copy-button"
            >
              {translate(phrases?.copy ?? "")}
            </Button>
          )}
          {hasRemoveButton && (
            <Button
              onClick={() => setIsModalVisible(true)}
              icon={<DeleteOutlined />}
              size="small"
              className="archive-button"
            >
              {translate(phrases?.delete ?? "")}
            </Button>
          )}

          {hasArchiveButton && (
            <Button
              icon={isArchival ? <RedoOutlined /> : <DeleteOutlined />}
              size="small"
              className={isArchival ? "unarchive-button" : "archive-button"}
            >
              {isArchival
                ? translate(phrases?.unarchive ?? "")
                : translate(phrases?.archive ?? "")}
            </Button>
          )}
          {customButton}
        </Space>
        <ConfirmDeleteModal
          isModalVisible={isModalVisible}
          translate={translate}
          handleConfirm={handleConfirm}
          handleCancel={() => setIsModalVisible(false)}
        />
      </>
    );
  }
);
