import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CustomLayout,
  DefaultViewModel,
  ModuleBuilder,
  useAuth,
  TPageComponent,
} from "shared-modules";

export const LogoutView: TPageComponent = ({ model }) => {
  const { logout } = useAuth0();
  const history = useHistory();

  console.log("-->", model.isAuthenticated);

  const handleLogout = useCallback(() => {
    logout({
      returnTo: `${window.location.origin}`,
      federated: true,
      localOnly: false,
    });
  }, [logout]);

  useEffect(() => {
    model.isAuthenticated ? handleLogout() : history.push("/");
  }, [model.isAuthenticated, history, handleLogout]);

  return null;
};

export default ModuleBuilder.configure(LogoutView, {
  model: () => new DefaultViewModel(),
  route: { path: "/logout", exact: true },
  accessControl: { authRequired: true },
});
