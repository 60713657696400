import { Button } from "antd";
import { observer } from "mobx-react-lite";
import React, {
  FormEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { CustomSelect, OptionType } from "../CustomSelect/CustomSelect";
import { TranslationPropsType } from "../../hocs/WithTranslation";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { CustomInput } from "../CustomInput/CustomInput";
import "./EditableData.less";

export type EditableInfoProps<T> = {
  getIsEditing?: () => boolean;
  save: (updatedData: T) => any;
  getData: () => { name: string | number; value?: boolean | string | number };
  options?: OptionType[];
  updateData: (info: any) => T;
  label: string;
  editDisabled?: boolean;
  translation: TranslationPropsType;
  emptyText?: string;
  clickableLabel?: boolean;
  link?: string;
  phrases?: { absence?: string };
};

export const EditableData = observer(
  <T extends any>({
    getData,
    updateData,
    label,
    translation,
    options,
    emptyText,
    save,
    getIsEditing,
    clickableLabel = true,
    link,
    phrases,
  }: EditableInfoProps<T>) => {
    const { name: dataName } = getData();

    const [inputValue, setInputValue] = useState("");
    const [isEditable, setIsEditable] = useState(false);

    const name =
      dataName || translation.translate(emptyText ?? phrases?.absence ?? "");

    const editableNode = useOnClickOutside<HTMLFormElement>({
      isActive: isEditable,
      onClickOutside: () => setIsEditable(false),
      excludedSelectors: ["ant-select-item-option-content"],
    });

    const edit = async (value?: string) =>
      await save(updateData((value ?? inputValue).trim()));

    const disableEdit = () => {
      setIsEditable(false);
    };

    const enableEdit: MouseEventHandler = () => {
      setIsEditable(true);
    };

    const handleEdit = async (value?: string) => {
      checkCanSubmit(value) && (await edit(value));
      disableEdit();
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleEdit();
    };

    useEffect(() => {
      setInputValue(String(dataName));
    }, [dataName, name]);

    const checkCanSubmit = (value: string = inputValue) =>
      isEditable && value && value !== dataName;

    const isEditing = getIsEditing?.();

    return isEditable ? (
      <form ref={editableNode} onSubmit={handleSubmit}>
        <Button
          type="link"
          className="editable-apply"
          icon={<CheckOutlined />}
          loading={isEditing}
          disabled={!checkCanSubmit()}
          htmlType="submit"
        />
        {options ? (
          <CustomSelect
            className="editable-input"
            value={inputValue}
            placeholder={String(name)}
            options={options}
            onChange={(_, options) => {
              setInputValue(options.children);
              handleEdit(options.children);
            }}
          />
        ) : (
          <CustomInput
            className="editable-input"
            maxLength={240}
            placeholder={label}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
      </form>
    ) : (
      <Button
        className="editable-button"
        type="text"
        icon={<EditOutlined className="editable-icon" onClick={enableEdit} />}
        onClick={clickableLabel ? enableEdit : undefined}
      >
        {link && dataName ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="editable-data-link"
          >
            {name}
          </a>
        ) : (
          <span
            className={classNames({
              "editable-empty": !dataName,
              "disable-pointer": !!dataName && !!link,
            })}
          >
            {name}
          </span>
        )}
      </Button>
    );
  }
);
