import ModelsProvider from "../ModelsProvider";

export default class GlobalModelsProvider extends ModelsProvider {
  constructor() {
    super();
    if (GlobalModelsProvider._instance) {
      return GlobalModelsProvider._instance;
    }

    GlobalModelsProvider._instance = this;
  }

  private static _instance: GlobalModelsProvider;
}
