import React, { FC } from "react";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import "./InternalLoadingIndicator.less";

export const InternalLoadingIndicator: FC<{ visible?: boolean; size?: number }> = ({ visible, size }) => (
  <>
    {visible && (
      <div className={"internal-loading-indicator"}>
        <LoadingIndicator size={size ?? 64} />
      </div>
    )}
  </>
);
