import { ClearOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { FC, useCallback } from "react";
import { CompactFilterSection } from "../CompactFiltersSection/CompactFiltersSection";
import { TopBarProps } from "../FIltersSection/FiltersSection";

import "./FiltersTopBar.less";

export const FiltersTopBar: FC<
  Omit<TopBarProps, "children"> & {
    phrases?: { collapse?: string; expand?: string; clear?: string };
  }
> = ({
  toggleIsFilterSectionExpanded,
  translate,
  isFilterSectionExpanded,
  filterLabels,
  model,
  phrases,
}) => {
  const onClearFiltersButtonPress = useCallback(
    () => model.clearFilters?.(),
    []
  );
  const { collapse, clear, expand } = phrases ?? {};
  return (
    <Space className="show-clear-filters-buttos">
      <Space className="show-button-with-compact-filters" size="middle">
        <Button
          className="toogle-filters-button"
          onClick={toggleIsFilterSectionExpanded}
          type="primary"
        >
          {translate(isFilterSectionExpanded ? collapse ?? "" : expand ?? "")}
        </Button>
        {!isFilterSectionExpanded && (
          <CompactFilterSection
            translate={translate}
            model={model}
            filterLabels={filterLabels}
          />
        )}
      </Space>
      <Button
        onClick={onClearFiltersButtonPress}
        danger
        icon={<ClearOutlined />}
      >
        {translate(clear ?? "")}
      </Button>
    </Space>
  );
};
