import { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { globalModels, MODELS_KEYS } from "../models/global";
import { getTokens } from "../helpers/auth";

export default function useAuth() {
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const userModel = globalModels.get(MODELS_KEYS.user);

  const getTokensCallback = useCallback(
    (refresh?: boolean) =>
      getTokens({
        getToken: getAccessTokenSilently,
        getClaim: getIdTokenClaims,
        refresh,
      }),
    []
  );

  useEffect(() => {
    userModel.setRefreshTokens(getTokensCallback);
  }, []);

  useEffect(() => {
    !isLoading && !isAuthenticated && loginWithRedirect();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    isAuthenticated &&
      !isLoading &&
      getTokensCallback()
        .then((tokens) =>
          userModel.authorize?.({
            user,
            error,
            isAuthenticated,
            isLoading,
            ...tokens,
          })
        )
        .catch(console.error);
  }, [
    isAuthenticated,
    isLoading,
    user,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    userModel,
  ]);

  return {
    isLoading: userModel.isLoading,
    isAuthenticated: userModel.isAuthenticated,
    user,
  };
}
