import { useAuth0 } from "@auth0/auth0-react";
import { ComponentType, useCallback } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import {
  AppRouterProvider,
  Auth,
  PrivateRoute,
  RouterPrividerTypes,
} from "shared-modules";

import * as Pages from "../pages";

AppRouterProvider.init(
  Route as ComponentType<RouterPrividerTypes.ExtendsRouteProps>,
  PrivateRoute,
  "/"
).build(Pages);

const AppRouter: React.FC = ({ children }) => {
  const renderRoutes = useCallback(
    (authData) => (
      <>
        <Switch>{AppRouterProvider.get().renderAll()}</Switch>
        {children}
      </>
    ),
    [children]
  );
  return (
    <HashRouter>
      <Auth loaderSelector="#app-loader" render={renderRoutes} />
    </HashRouter>
  );
};

export default AppRouter;
