import { observable, action, makeObservable } from "mobx";
import storages from "../../../services/Storage/Storage";
import { Tokens } from "../../../helpers/auth";
import {
  APP_METADATA_URL_KEY,
  USER_ROLES,
} from "../../../core/globalConstants";
// import { setAxiosClientAuthHeaders } from "../../../services/api/axios-client";

type RefreshToken = (refresh?: boolean) => Promise<Tokens>;

type SetAxtiosClientAuthHeader = (accessToken: string, idToken: string) => any;

export class UserModel {
  data: any = null;
  isAuthenticated = false;
  isLoading = true;
  error: any = null;
  accessToken = "";
  idToken = "";
  _setAxiosClientAuthHeaders?: SetAxtiosClientAuthHeader;
  private _refreshTokens?: RefreshToken;

  constructor(options?: {
    setAxiosClientAuthHeaders?: SetAxtiosClientAuthHeader;
  }) {
    this._setAxiosClientAuthHeaders = options?.setAxiosClientAuthHeaders;
    makeObservable(this, {
      data: observable.ref,
      isAuthenticated: observable,
      isLoading: observable,
      accessToken: observable,
      idToken: observable,
      authorize: action.bound,
      refreshTokens: action.bound,
    });
  }

  authorize(options: {
    isAuthenticated: boolean;
    user: any;
    error: any;
    accessToken: string;
    idToken: string;
    isLoading: boolean;
  }): boolean {
    if (options.user) {
      this.data = options.user;
      storages.local.set({ activeUser: options.user.email });
    }
    if (options.error) {
      this.error = options.error;
    }
    if (options.accessToken) {
      this.accessToken = options.accessToken;
    }
    if (options.idToken) {
      this.idToken = options.idToken;
    }
    this.isLoading = options.isLoading;
    return (this.isAuthenticated = options.isAuthenticated);
  }

  setRefreshTokens(refresh: RefreshToken): void {
    this._refreshTokens = refresh;
  }

  private setAxiosClientAuthHeaders(accessToken: string, idToken: string) {
    this._setAxiosClientAuthHeaders?.(accessToken, idToken);
  }

  async refreshTokens(refresh?: boolean): Promise<Tokens> {
    const tokens = await this._refreshTokens?.(refresh);
    if (tokens) {
      this.idToken = tokens.idToken;
      this.accessToken = tokens.accessToken;
      this.setAxiosClientAuthHeaders(this.accessToken, this.idToken);
    }

    return { idToken: this.idToken, accessToken: this.accessToken };
  }

  getRole(): USER_ROLES | undefined {
    return this.data?.[APP_METADATA_URL_KEY]?.role;
  }
}
