import React, { FC, Key, useCallback, useMemo } from "react";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { GetPhrase } from "../../hocs/WithTranslation";
import "./CategorySelectionModal.less";
import TableDataModel from "../../models/TableDataModel";

export type TreeNode = {
  key: string;
  title: string;
  children?: TreeNode[];
};

interface TreeSelectProps<M> {
  model?: M;
  translate: GetPhrase;
  isTreeVisible?: boolean;
  treeNodes?: TreeNode[];
  setCategories?: (categoryKey: Key | Key[]) => void;
  categoryKeys?: Key | Key[];
}

export type ArticleGroup = {
  Id: number;
  Code: string;
  Path: string;
};

export type TreeSelectType<M> = FC<TreeSelectProps<M>>;

type CategorySelectionModalProps<M, G = any> = {
  allCategories?: G[];
  translate: GetPhrase;
  isLoading?: boolean;
  isVisible: boolean;
  onChange?: (value?: Key | Key[]) => void;
  closeModal: () => void;
  categoryId?: Key | Key[];
  TreeSelectComponent: TreeSelectType<M>;
  phrases?: { selectCategory?: string; cancel?: string };
};

export const CategorySelectionModal = observer(
  <M extends TableDataModel, G extends ArticleGroup>({
    allCategories,
    translate,
    isLoading,
    isVisible,
    onChange,
    categoryId,
    closeModal,
    phrases,
    TreeSelectComponent,
  }: CategorySelectionModalProps<M, G>) => {
    const getChildrenNodes = useCallback(
      (parentPath: string): TreeNode[] | undefined => {
        const childrenCategories = allCategories?.filter?.(
          (childrenCategory) =>
            childrenCategory.Path.includes(parentPath) &&
            childrenCategory.Path.lastIndexOf("/") === parentPath?.length
        );

        if (childrenCategories?.length) {
          return childrenCategories.map((childrenCategory) => {
            const childrenTreeNodes = getChildrenNodes(childrenCategory.Path);
            return childrenTreeNodes
              ? {
                  title: childrenCategory.Path,
                  key: `${childrenCategory.Id}`,
                  children: childrenTreeNodes,
                }
              : {
                  title: childrenCategory.Path,
                  key: `${childrenCategory.Id}`,
                };
          });
        }
        return;
      },
      [allCategories]
    );

    const treeNodes: TreeNode[] | undefined = useMemo(
      () =>
        allCategories
          ?.filter?.((category) => !category.Path.includes("/"))
          .map((parentCategory) => {
            const childrenTreeNodes = getChildrenNodes(parentCategory.Path);
            return childrenTreeNodes
              ? {
                  title: parentCategory.Path,
                  key: `${parentCategory.Id}`,
                  children: childrenTreeNodes,
                }
              : {
                  title: parentCategory.Path,
                  key: `${parentCategory.Id}`,
                };
          }),
      [allCategories]
    );

    return (
      <Modal
        visible={isVisible}
        cancelText={translate(phrases?.cancel ?? "")}
        onCancel={() => {
          onChange?.(undefined);
          closeModal();
        }}
        onOk={closeModal}
        title={translate(phrases?.selectCategory ?? "")}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <TreeSelectComponent
            translate={translate}
            isTreeVisible={true}
            treeNodes={treeNodes}
            setCategories={
              onChange ? (value: Key | Key[]) => onChange(value) : undefined
            }
            categoryKeys={categoryId}
          />
        )}
      </Modal>
    );
  }
);
