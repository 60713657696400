import { ComponentType, createElement } from "react";
import {
  IRouterProvider,
  IRoutes,
  AccessControl,
  ExtendsRouteProps,
} from "./RouterProvider.types";
import { RouteProps } from "react-router-dom";
import { globalModels, MODELS_KEYS } from "../../models/global";

export default class RouterProvider implements IRouterProvider {
  routeComponent;
  privateRouteComponent;
  redirectTo;
  user = globalModels.get(MODELS_KEYS.user);
  routes = {} as IRoutes;

  constructor(
    routeComponent: ComponentType<ExtendsRouteProps>,
    privateRouteComponent: ComponentType<ExtendsRouteProps>,
    redirectTo: string
  ) {
    this.routeComponent = routeComponent;
    this.privateRouteComponent = privateRouteComponent;
    this.redirectTo = redirectTo;
  }

  register(path: string, params: RouteProps) {
    this.routes[path] = { ...params, path };
    return this;
  }

  getRoute(path: string) {
    return this.routes[path];
  }

  getAllRoutes({ iterable = false }) {
    return iterable ? Object.values(this.routes) : this.routes;
  }

  render(path: string) {
    const { path: _, ...props } = this.getRoute(path) || {};

    return createElement(this.routeComponent, { path, ...props }, null);
  }

  renderAll() {
    return Object.entries(this.routes).map(
      ([path, { path: _, accessControl, ...props }], index) => {
        const { isPrivate, roleIsValid } =
          this.checkAccessPermisions(accessControl);

        const key = `${path}-${index}`;
        const matchedProps = isPrivate
          ? { keyName: key, to: this.redirectTo, roleIsValid }
          : { key };
        const matchedRouteComponent = isPrivate
          ? this.privateRouteComponent
          : this.routeComponent;
        return createElement(
          matchedRouteComponent,
          { path, ...props, ...matchedProps },
          null
        );
      }
    );
  }

  private checkAccessPermisions(accessControl?: AccessControl) {
    return {
      isPrivate: !!accessControl?.authRequired,
      roleIsValid:
        accessControl?.permittedRoles?.includes(this.user.getRole()) ?? true,
    };
  }
}
