import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Detector from "i18next-browser-languagedetector";

export type ResourcesType = Record<
  string,
  { translation: Record<string, string> }
>;

export enum LANG_KEYS {
  plPL = "pl",
  enGB = "en",
  deDE = "de",
}

const { plPL, enGB, deDE } = LANG_KEYS;

const availableLanguages = [plPL, enGB, deDE] as const;

export type AvailableLanguagesType = typeof availableLanguages[number];

const fallbackLng: { [key: string]: string[] } = {};
availableLanguages.forEach((item) => {
  fallbackLng[item] = [""];
});
fallbackLng.default = [...window.navigator.languages, "pl"];

export default function initTranslator(resources: ResourcesType): typeof i18n {
  i18n
    .use(Detector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng,
      whitelist: availableLanguages,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          "localStorage",
          "navigator",
          "querystring",
          "cookie",
          "sessionStorage",
          "htmlTag",
        ],
      },
    });
  return i18n;
}

// export default i18n;
