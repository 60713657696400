import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import {
  CustomLayout,
  DefaultViewModel,
  ModuleBuilder,
  TPageComponent,
} from "shared-modules";

import "./Dashboard.less";

export const DashboardView: TPageComponent = ({ model, children }) => {
  return (
    <CustomLayout title="WMS dashboard" className="dashboard-layout">
      <Space direction="vertical">
        <Typography.Text>{model.user.data.name}</Typography.Text>
        <Link to="/logout">Logout</Link>
      </Space>
    </CustomLayout>
  );
};

export default ModuleBuilder.configure(DashboardView, {
  model: () => new DefaultViewModel(),
  route: { path: "/", exact: true },
  accessControl: { authRequired: true },
});
