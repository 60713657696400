import React, {
  FC,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Input, TablePaginationConfig } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { ProColumns } from "@ant-design/pro-table";

import { CustomTableProps } from "../components/CustomTable/CustomTable";
import {
  FilterDropdown,
  FilterDropdownProps,
  FilterModelType,
} from "../components/FilterDropdown/FilterDropdown";
import { Key } from "antd/lib/table/interface";
import { GetPhrase, TranslationPropsType } from "./WithTranslation";

type RecordT<T = any> = { [key: string]: number | string | symbol } & T;

type Sorter = (a: RecordT, b: RecordT) => boolean;

type RenderButtons<T> = (text: string, record: RecordT<T>) => JSX.Element;
type RenderCollapsableCode = (codes: string[]) => JSX.Element;
type RenderActiveIcon = (isActive: boolean) => JSX.Element;

export type ColumnData<T = any> = {
  title: string;
  dataIndex?: string;
  key: string;
  width?: string;
  fixed?: string;
  filterEnable?: boolean;
  sortEnable?: boolean;
  sorter?: Sorter | boolean;
  render?: RenderButtons<T> | RenderCollapsableCode | RenderActiveIcon;
  [key: string]: any;
};

type DataScope = { limit: number | null; offset: number | null };

export type DataScopeModelType<T = any> = {
  searchText: string;
  searchedColumn: any;
  title: string;
  tableColumns: any;
  setTableColumns: any;
  dataScope: DataScope;
  isApiError?: boolean;
  tableRowKeys?: Key[];
  setTableRowKeys?: (keys: Key[]) => void;
} & FilterModelType & {
    setFilter?: (name: string, filter?: string) => void;
    clearFilters?: () => any;
    filters?: { [key: string]: any };
  };

type WithDataScopeHocProps<TableData = any> = Omit<
  CustomTableProps<any>,
  | "columns"
  | "pageName"
  | "tableColumns"
  | "setTableColumns"
  | "isDataLoading"
  | "dataSource"
> & {
  model: DataScopeModelType<TableData>;
  tableData: TableData;
  getData: (params: AllObjects, scope: DataScope) => any;
  disableLimitAndOffset?: boolean;
  fetchQuantity?: (params: AllObjects) => any;
  getDataQuantity?: () => number;
  getIsLoading: () => boolean;
  translation: TranslationPropsType;
  disableInsideTableFilters?: boolean;
  disableInsideTableSort?: boolean;
};

type AllObjects<T = any> = { [key: string]: T };

export type SorterType = {
  columnKey?: string;
  field?: string;
  order?: string;
  column?: any;
};

export function withDataScope<
  M = any,
  P = AllObjects,
  TableData = any,
  T = AllObjects
>(
  Component: FC<any>,
  getColumnsData: (model: any, translate: GetPhrase) => ColumnData[],
  getExpandableRow?: (
    model: any,
    translate: GetPhrase
  ) => { [key: string]: any }
) {
  const Hoc: FC<WithDataScopeHocProps<TableData>> = observer<
    WithDataScopeHocProps<TableData>
  >(
    ({
      model,
      getIsLoading,
      disableLimitAndOffset,
      fetchQuantity,
      getData,
      getDataQuantity,
      translation,
      disableInsideTableFilters,
      disableInsideTableSort,
      tableData,
      ...rest
    }) => {
      const {
        searchText,
        searchedColumn,
        title,
        tableColumns,
        setTableColumns,
      } = model;
      const inputRef = useRef<Input>(null);
      const [tableHeadersRefs, setTableHeadersRefs] = useState<
        HTMLDivElement[]
      >([]);

      useEffect(() => {
        tableHeadersRefs.forEach((ref) => {
          (ref?.parentNode?.nextSibling as HTMLElement)?.classList.add(
            "align-self-flext-start"
          );
        });
      }, [tableHeadersRefs]);

      const compareFilterParams = useCallback(
        compareParamsFactory<AllObjects>(),
        []
      );

      const getColumnSearchProps = useCallback(
        (dataIndex, dataName, filterEnable) =>
          disableInsideTableFilters || !filterEnable
            ? {}
            : {
                filterDropdown: (props: FilterDropdownProps) => (
                  <FilterDropdown<T>
                    {...props}
                    model={model}
                    dataIndex={dataIndex}
                    dataName={dataName}
                    inputRef={inputRef}
                    translate={translation.translate}
                  />
                ),
                filterIcon: (filtered: boolean) => (
                  <SearchOutlined
                    className={classNames({ "highlight-icon": filtered })}
                  />
                ),
                onFilter: (value: string, record: RecordT<T>) =>
                  record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",

                onFilterDropdownVisibleChange: (visible: boolean) => {
                  if (visible) {
                    setTimeout(() => inputRef?.current?.select(), 100);
                  }
                },
                render: (text: string) =>
                  searchedColumn === dataIndex ? (
                    <Highlighter
                      highlightClassName="highlight-text"
                      searchWords={[searchText]}
                      autoEscape
                      textToHighlight={text ? text.toString() : ""}
                    />
                  ) : (
                    text
                  ),
              },
        [model, searchText, searchedColumn]
      );

      const handleDataScopeChange = useCallback(
        async (
          pagination: TablePaginationConfig,
          filters: AllObjects<string[] | null>,
          sorter: SorterType
        ) => {
          const sortParams = disableInsideTableSort
            ? {}
            : getSortingParams(sorter);
          const params = disableInsideTableFilters
            ? model.filters ?? {}
            : getParams(filters);
          fetchQuantity &&
            !disableInsideTableFilters &&
            compareFilterParams(params) &&
            (await fetchQuantity(params));
          const scope = setLimitAndOffset(
            pagination,
            model.dataScope.limit,
            params
          );
          const isPageChanged = checkPageChange(model.dataScope, pagination);
          if (
            !disableInsideTableSort &&
            !disableInsideTableFilters &&
            !isPageChanged
          ) {
            model.setFilter?.("sortby", sortParams.sortby);
          } else {
            await getData({ ...params, ...sortParams }, scope);
          }
        },
        [model, model.filters, disableInsideTableFilters]
      );

      const columns = useMemo(
        () =>
          getColumnsData(model, translation.translate).map(
            ({ title, subtitle, key, filterEnable, sortEnable, ...rest }) => ({
              title: (
                <div
                  ref={
                    subtitle
                      ? (r) =>
                          setTableHeadersRefs(
                            (refs) => [...refs, r] as HTMLDivElement[]
                          )
                      : undefined
                  }
                  className="table-column-title"
                >
                  <div>{translation.translate(title)}</div>
                  <div>{translation.translate(subtitle)}</div>
                </div>
              ),
              key,
              dataIndex: key,

              sorter:
                disableInsideTableSort || !sortEnable
                  ? undefined
                  : (a, b) => {
                      if (key.toLowerCase() === "id") {
                        return a?.[key] - b?.[key];
                      }
                      const lowerCaseA: string = a?.[key]
                        .toString()
                        .toLowerCase();
                      const lowerCaseB: string = b?.[key]
                        .toString()
                        .toLowerCase();
                      return lowerCaseA.localeCompare(lowerCaseB);
                    },
              ...getColumnSearchProps(key, title, !!filterEnable),
              ...rest,
            })
          ) as ProColumns<P & AllObjects>[],
        [getColumnSearchProps, translation.currentLang]
      );

      const paginationConfig = {
        total: getDataQuantity && getDataQuantity(),
        defaultPageSize: 10,
        current: model.dataScope.offset ? model.dataScope.offset / 10 + 1 : 1,
        showQuickJumper: true,
      };

      return (
        <Component
          columns={columns}
          dataSource={tableData}
          isDataLoading={getIsLoading()}
          isApiError={model?.isApiError}
          pageName={title}
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          onChange={disableLimitAndOffset ? undefined : handleDataScopeChange}
          pagination={paginationConfig}
          expandableRow={getExpandableRow?.(model, translation.translate)}
          translation={translation}
          selectedRowKeys={model.tableRowKeys}
          setSelectedRowKeys={model.setTableRowKeys}
          {...rest}
        />
      );
    }
  );
  return Hoc;
}

function getParams(filters: AllObjects<string[] | null>) {
  return Object.fromEntries(
    Object.entries<any>(filters)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key.toLowerCase(), value?.join("")?.trim()])
  );
}

function setLimitAndOffset(
  pagination: TablePaginationConfig,
  currentLimit: number | null,
  params?: AllObjects
) {
  let limit = null,
    offset = null;
  const { pageSize, current } = pagination ?? {};
  if (pageSize && current) {
    limit = pageSize;
    offset =
      pagination.current === 1 || currentLimit !== pagination.pageSize
        ? null
        : pageSize * (current - 1);
  }
  if (params) {
    params.limit = limit;
    params.offset = offset;
  }
  return { limit, offset };
}

function compareParamsFactory<T = AllObjects>(): (params: T) => boolean {
  let _prevParams = "";
  return (params: T): boolean => {
    const serializedParams = JSON.stringify(params);
    if (_prevParams === serializedParams) {
      return false;
    }
    _prevParams = serializedParams;
    return true;
  };
}

const SORT_ORDERS_ABBREVIATIONS: { [key: string]: string } = Object.freeze({
  ascend: "asc",
  descend: "desc",
});

function getSortingParams(sorter: SorterType) {
  if (sorter?.order) {
    return {
      sortby: `${sorter.column.sorterKey || sorter.columnKey}.${
        SORT_ORDERS_ABBREVIATIONS[sorter.order]
      }`,
    };
  }
  return {};
}

function checkPageChange(
  dataScope: { limit: number | null; offset: number | null },
  pagination: TablePaginationConfig
) {
  const sameLimit = dataScope.limit === pagination.pageSize;
  const sameOffset =
    (dataScope.offset === null && pagination.current === 1) ||
    dataScope.offset ===
      Number(pagination.pageSize) * (Number(pagination.current) - 1);
  return !(sameLimit && sameOffset);
}
