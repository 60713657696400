import { DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";
import React, { ReactNode, useMemo } from "react";
import { GetPhrase } from "../../hocs/WithTranslation";

interface FiltersGroupsActionsDropdownProps {
  actions?: { label: string; onClick?: () => void; icon?: ReactNode }[];
  translate: GetPhrase;
  currentLang: string;
  phrases?: { views?: string };
}

export const FiltersGroupsActionsDropdown: React.FC<FiltersGroupsActionsDropdownProps> =
  ({ actions, translate, currentLang, phrases }) => {
    const menu = useMemo(
      () => (
        <Menu>
          {actions?.map((option, index) => (
            <Menu.Item icon={option.icon} onClick={option.onClick} key={index}>
              {translate(option.label)}
            </Menu.Item>
          ))}
        </Menu>
      ),
      [currentLang]
    );
    return (
      <Dropdown overlay={menu}>
        <Button type="primary">
          {translate(phrases?.views ?? "")} <DownOutlined />
        </Button>
      </Dropdown>
    );
  };
