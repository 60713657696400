import { observer as mobxObserver } from "mobx-react-lite";
import bindParams, { Options } from "../../helpers/bindParams";
import { withTranslation } from "../../hocs/WithTranslation";
import { BaseViewModel } from "../BaseViewModel";
import { IRouterProvider } from "../RouterProvider/RouterProvider.types";
import { TPageComponent } from "../types";
import {
  Connector,
  IComponentWithParams,
  IModuleBuilder,
  Localizator,
  ModelBuilderConstructorParams,
  Observer,
} from "./ModuleBuilder.types";

export class ModuleBuilder<M extends BaseViewModel = any>
  implements IModuleBuilder<M>
{
  connector: Connector;
  observer: Observer;
  component: IComponentWithParams;
  routerProvider: IRouterProvider;
  localizator: Localizator;
  model: M;

  constructor({
    component,
    connector,
    observer = mobxObserver,
    localizator = withTranslation,
    routerProvider,
    model,
  }: ModelBuilderConstructorParams<M>) {
    this.connector = connector;
    this.observer = observer;
    this.localizator = localizator;
    this.component = component;
    this.routerProvider = routerProvider;
    this.model = model;
  }

  public connectModel(model?: any, loaderSelector?: string) {
    if (!this.connector) {
      return this;
    }

    model = this.component?.model ?? model;
    this.model = typeof model === "function" ? model() : model;

    if (this.model) {
      this.component = this.connector({
        model: this.model,
        component: this.component,
        loaderSelector,
      });
    }

    return this;
  }

  public subscribe() {
    this.component = this.observer(this.component);

    return this;
  }

  public addToRouter(route?: string) {
    route = route ?? this.component?.route?.path;

    route &&
      this.routerProvider?.register(route ?? "", {
        component: this.component,
        exact: this.component?.route?.exact,
        accessControl: this.component.accessControl,
      });

    return this;
  }

  public localize() {
    if (this.localizator) {
      this.component = this.localizator({ component: this.component });
    }
    return this;
  }

  public build() {
    return this.component;
  }

  static configure(View: TPageComponent, options: Options) {
    return bindParams(View, options) as IComponentWithParams;
  }
}
