import { Auth0ProviderOptions } from "@auth0/auth0-react";

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

export const Auth0ProviderProps: Auth0ProviderOptions = Object.freeze({
  domain: REACT_APP_AUTH0_DOMAIN as string,
  clientId: REACT_APP_AUTH0_CLIENT_ID as string,
  audience: REACT_APP_AUTH0_AUDIENCE as string,
  scope: REACT_APP_AUTH0_SCOPE as string,
  response_type: "code",
});

export enum USER_ROLES {
  SYSTEM_ADMINISTRATOR = "SystemAdministrator",
  ORGANIZATION_ADMINISTRATION = "OrganizationAdministrator",
  USER = "User",
  PARTNER = "Partner",
}

export const APP_METADATA_URL_KEY = "https://futuriti.pl/app_metadata";

export const TRACKING_BASE_URL = "https://tracking.xsale.ai/#";
export const SIMPLE_LIST_LIMIT = 1000;

export const WMS_PACKING_URL =
  "https://online.xsale.ai/function/xsale-micro-frontend-module-packing/orders";
export const COURIERS_INTEGRATION_URL =
  "https://online.xsale.ai/Admin/Integration/RunEdit/1?moduleId=38";

export const providersUrlsGetters: {
  [key: string]: (waybill?: string | null) => string;
} = {
  ["dpd"]: (waybill) =>
    `https://tracktrace.dpd.com.pl/parcelDetails?p1=${waybill}`,
  ["inpost"]: (waybill) =>
    `https://inpost.pl/sledzenie-przesylek?number=${waybill}`,
  ["dhl"]: (waybill) =>
    `https://www.dhl.com/pl-pl/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=${waybill}`,
  ["fedex"]: (waybill) =>
    `https://www.fedex.com/fedextrack/?tracknumbers=${waybill}&cntry_code=pl`,
  ["poczta polska"]: (waybill) =>
    `https://emonitoring.poczta-polska.pl/?numer=${waybill}`,
  ["świat przesyłek"]: (waybill) => `https://swiatprzesylek.pl/tt/${waybill}`,
  ["Paczka w Ruchu"]: (waybill) =>
    `https://www.paczkawruchu.pl/sledz-paczke/?numer=${waybill}`,
  ["europaczka"]: (waybill) =>
    `http://83.144.109.138/xsc/XTrace/Default.aspx?Shipment.RefNo=${waybill}`,
  ["geis"]: (waybill) =>
    `https://www.geis.pl/pl/sledzenie-przesylki?p=${waybill}`,
  ["kex"]: (waybill) => `https://www.epaka.pl/sledzenie-przesylek/${waybill}`,
  ["gls-cz"]: (waybill) =>
    `https://gls-group.eu/CZ/cs/sledovani-zasilek.html?match=${waybill}`,
  ["gls-hu"]: (waybill) =>
    `https://gls-group.eu/HU/hu/csomagkovetes.html?match=${waybill}`,
  ["gls-pl"]: (waybill) =>
    `https://gls-group.eu/PL/pl/sledzenie-paczek.html?match=${waybill}`,
  ["gls-ro"]: (waybill) =>
    `https://gls-group.eu/RO/ro/urmarire-colet.html?match=${waybill}`,
  ["gls-sk"]: (waybill) =>
    `https://gls-group.eu/SK/sk/sledovanie-zasielok.html?match=${waybill}`,
  ["royal mail"]: (waybill) =>
    `https://royalmail.com/track-your-item#/tracking-results/${waybill}`,
  ["dpd-de"]: () =>
    `https://www.dpd.com/de/de/empfangen/sendungsverfolgung-und-live-tracking`,
  ["sky-logistycs"]: () => `https://www.skylogisticspl.com/pl/tracking`,
};

export const CLOSE_MODAL_DELAY = 300;
