import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { TranslationPropsType } from "../../hocs/WithTranslation";
import "./CustomRouteModal.less";

export type RenderModal = (props: {
  close?: () => any;
  params?: any;
}) => JSX.Element;

export type CustomRouteModalProps = {
  onConfirm?: () => any | Promise<any>;
  render: RenderModal;
  redirectUrl: string;
  okText?: string;
  translation: TranslationPropsType;
  title?: string;
  model?: { isValid: boolean; [key: string]: any };
  visible?: boolean;
  autoRedirectDisabled?: boolean;
  phrases?: { confirm?: string; cancel?: string };
};

export const CustomRouteModal: FC<CustomRouteModalProps> = observer(
  ({
    render,
    redirectUrl,
    onConfirm,
    okText,
    translation,
    title,
    model,
    visible,
    autoRedirectDisabled,
    phrases,
  }) => {
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const history = useHistory();
    const onOk = useCallback(async () => {
      setLoading(true);
      await onConfirm?.();
      setLoading(false);
      !autoRedirectDisabled && history.push(redirectUrl);
    }, []);
    const onCancel = useCallback(async () => {
      history.push(redirectUrl);
    }, [redirectUrl]);

    return (
      <Modal
        className="custom-route-modal"
        centered
        title={translation?.translate(title as string)}
        okText={translation?.translate(okText ?? phrases?.confirm ?? "")}
        cancelText={translation?.translate(phrases?.cancel ?? "")}
        onOk={onOk}
        onCancel={onCancel}
        visible={visible ?? true}
        okButtonProps={{ disabled: !model?.isValid }}
      >
        {render({ close: onConfirm, params: { ...params, loading } })}
      </Modal>
    );
  }
);
