import React from "react";
import { DatePicker, Space } from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";
import moment, { Moment } from "moment";

interface CustomDatePickerProps {
  label?: string | JSX.Element;
  value?: string;
  onChange?: (value: Moment | null, string: string) => void;
  className?: string;
  allowClear?: boolean;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  onChange,
  value,
  className,
  allowClear,
}) => {
  return (
    <Space>
      {label}
      <DatePicker
        className={className}
        value={value ? moment(value) : null}
        onChange={onChange}
        locale={locale}
        placeholder="rrrr-mm-dd"
        allowClear={allowClear}
      />
    </Space>
  );
};
