import { CaretUpOutlined } from "@ant-design/icons";
import { Empty, Select, Space } from "antd";
import { RefSelectProps } from "rc-select";
import React, { useState } from "react";
import { GetPhrase } from "../../hocs/WithTranslation";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

import "./CustomSelect.less";

interface CustomSelectProps {
  options?: OptionType[];
  onChange?: (value: string, option?: any) => void;
  defaultValue?: string;
  placeholder?: string;
  showSearch?: boolean;
  className?: string;
  value?: string;
  mode?: "multiple" | "tags";
  isInsideModal?: boolean;
  allowClear?: boolean;
  translate?: GetPhrase;
  disabled?: boolean;
  hideWhenNotSpread?: boolean;
  onHide?: () => void;
  isLoading?: boolean;
  dropdownClassName?: string;
  onDropdownVisibleChange?: () => void;
  phrases?: { collapse?: string; noData?: string };
}

export type OptionType = {
  value: string;
  name: string;
  disabled?: boolean;
};

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onChange,
  defaultValue,
  placeholder,
  showSearch,
  className,
  value,
  mode,
  // This prop is required when you want to display Select inside Modal component.
  // Without this prop dropdown will be rendered beneath the Modal.
  isInsideModal,
  allowClear,
  translate,
  disabled,
  hideWhenNotSpread,
  onHide,
  isLoading,
  dropdownClassName,
  onDropdownVisibleChange,
  phrases,
}) => {
  const [selectRef, setSelectRef] = useState<RefSelectProps | null>(null);
  return (
    <Select
      disabled={disabled}
      ref={(r) => setSelectRef(r)}
      dropdownClassName={
        isInsideModal ? "select-inside-modal" : dropdownClassName
      }
      className={className}
      showSearch={showSearch}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      filterOption={(input, option) =>
        option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      mode={mode}
      defaultOpen={hideWhenNotSpread}
      allowClear={allowClear}
      onBlur={onHide}
      onDropdownVisibleChange={onHide ?? onDropdownVisibleChange}
      dropdownRender={
        options?.length
          ? mode === "multiple"
            ? (originNode) => {
                return (
                  <>
                    {originNode}
                    <Space
                      onClick={() => selectRef?.blur()}
                      className="select-confirm-container"
                    >
                      {translate?.(phrases?.collapse ?? "")}
                      <CaretUpOutlined />
                    </Space>
                  </>
                );
              }
            : undefined
          : () =>
              isLoading ? (
                <LoadingIndicator />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={translate?.(phrases?.noData ?? "")}
                />
              )
      }
    >
      {options?.map((option: OptionType, index: number) => (
        <Select.Option key={index} value={option.value}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
};
