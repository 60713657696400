import React, { useCallback, useMemo, useState } from "react";
import { Checkbox, Space, Tag } from "antd";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { ConfirmDeleteModal } from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import { CustomInput } from "../../../components/CustomInput/CustomInput";
import { GetPhrase } from "../../../hocs/WithTranslation";
import { createActionHandler } from "../../../helpers/helpers";
import { CLOSE_MODAL_DELAY } from "../../../core/globalConstants";

type ParameterContainerProps<Param = any, UpdateParam = any> = {
  parameter: Param;
  translate: GetPhrase;
  deleteParameter: (parameterId: number) => Promise<boolean>;
  updatedParameters: UpdateParam[];
  updateParameter: (value: UpdateParam) => void;
  phrases: {
    deleteSuccess: string;
    deleteFailed: string;
    insertNewValue: string;
    required: string;
    priority: string;
  };
};

export const ParameterContainer: React.FC<ParameterContainerProps> = observer(
  ({
    updatedParameters,
    updateParameter,
    parameter,
    translate,
    deleteParameter,
    phrases,
  }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    const parameterValue = useMemo(
      () =>
        updatedParameters?.filter(
          (updatedParameter) => updatedParameter.Id === parameter.ParameterId
        )[0]?.Value ?? parameter.Values[0].Value,
      [
        updatedParameters?.filter(
          (updatedParameter) => updatedParameter.Id === parameter.ParameterId
        )[0],
        parameter,
      ]
    );

    const isPriority = useMemo(
      () =>
        updatedParameters?.filter(
          (updatedParameter) => updatedParameter.Id === parameter.ParameterId
        )[0]?.IsPriority ?? parameter.IsPriority,
      [
        updatedParameters?.filter(
          (updatedParameter) => updatedParameter.Id === parameter.ParameterId
        )[0],
        parameter,
      ]
    );
    const confirmDelete = useCallback(async () => {
      const handleDelete = createActionHandler({
        action: () => deleteParameter(parameter.ParameterId),
        messages: {
          success: translate(phrases.deleteSuccess),
          fail: translate(phrases.deleteFailed),
        },
      });
      await handleDelete();
      setTimeout(() => setIsModalVisible(false), CLOSE_MODAL_DELAY);
    }, [parameter]);

    return (
      <>
        <Space
          className={classNames("parameter-container", {
            required: parameter.IsRequired,
          })}
          wrap
        >
          <Space direction="vertical">
            <div
              className={classNames("parameter-labels", {
                required: parameter.IsRequired,
              })}
            >
              {parameter.Name}{" "}
              <p>{parameter.IsRequired && translate(phrases.required)}</p>
            </div>
            <Space className={`value-container`}>
              {isEdited ? (
                <CustomInput
                  placeholder={translate(phrases.insertNewValue)}
                  value={parameterValue}
                  autoFocus
                  onChange={(e) =>
                    updateParameter({
                      Id: parameter.ParameterId,
                      IsPriority: isPriority,
                      Value: e.target.value,
                    })
                  }
                  onBlur={() => setIsEdited(false)}
                />
              ) : (
                <Tag
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    setIsModalVisible(true);
                  }}
                  className="value-tag"
                  onClick={() => setIsEdited(true)}
                >
                  {parameterValue}
                </Tag>
              )}
            </Space>
          </Space>
          <Checkbox
            className="priority-checkbox"
            checked={isPriority}
            onChange={(e) =>
              updateParameter({
                Id: parameter.ParameterId,
                IsPriority: e.target.checked,
                Value: parameterValue,
              })
            }
          >
            {translate(phrases.priority)}
          </Checkbox>
        </Space>
        <ConfirmDeleteModal
          isModalVisible={isModalVisible}
          translate={translate}
          handleConfirm={confirmDelete}
          handleCancel={() => setIsModalVisible(false)}
        />
      </>
    );
  }
);
