import { Radio, RadioChangeEvent, Space } from "antd";
import React from "react";
import { GetPhrase } from "../../hocs/WithTranslation";
export type SwitchOption = {
  label: string;
  value: any;
};

interface CustomSwitchProps {
  options: SwitchOption[];
  label: string;
  value: any;
  onChange: (e: RadioChangeEvent) => void;
  translate?: GetPhrase;
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({
  options,
  label,
  value,
  onChange,
  translate,
}) => {
  return (
    <Space>
      {label}
      <Radio.Group
        options={options}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
    </Space>
  );
};
