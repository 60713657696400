import GlobalModelsProvider from "../../core/GlobalModelsProvider/GlobalModelsProvider";
import { UserModel } from "./UserModel/UserModel";

enum MODELS_KEYS {
  user,
}

const globalModels = new GlobalModelsProvider();

globalModels.add({ [MODELS_KEYS.user]: new UserModel() });

export { globalModels, MODELS_KEYS };
