import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { IwithModel, withModel } from "../../../hocs/WithModel";
import { RedirectTypes } from "../../../tools/Authenticator/Authenticator";
import { DefaultViewModel } from "../../BaseViewModel";
import { ModuleBuilder } from "../../ModuleBuilder/ModuleBuilder";
import { IComponentWithParams } from "../../ModuleBuilder/ModuleBuilder.types";
import { TPageComponent } from "../../types";

export interface PrivateRouteProps extends RouteProps {
  model: any;
  to: string;
  state?: any;
  keyName: string | number;
  roleIsValid: boolean;
  path: string;
}

const { auth, role } = RedirectTypes;

export const PrivateRoute: TPageComponent = ({
  model,
  component,
  to,
  state,
  path,
  keyName,
  roleIsValid,
}) => {
  const accessPermitted = model.isAuthenticated && roleIsValid;
  return accessPermitted ? (
    <Route path={path} key={String(keyName)} component={component} />
  ) : (
    <Route
      path={path}
      key={String(keyName)}
      render={() => (
        <Redirect
          to={{
            pathname: to,
            state: { from: path, type: roleIsValid ? role : auth },
          }}
        />
      )}
    />
  );
};

const options = {
  connector: withModel as IwithModel<IComponentWithParams>,
  component: ModuleBuilder.configure(PrivateRoute, {
    model: () => new DefaultViewModel(),
  }),
};

export default new ModuleBuilder(options)
  .subscribe()
  .connectModel(undefined, "#app-loader")
  .build();
