import storages from "../services/Storage/Storage";

type SelectedOrganization = {
  selectedOrganization: { name?: string; shortName?: string };
};

export const getUserSettings = (): SelectedOrganization | undefined => {
  return storages.local.get<SelectedOrganization>("userSettings");
};

export const clearSelectedOrganization = () => {
  const userSettings = getUserSettings();
  if (userSettings) {
    const { selectedOrganization: _, ...restSettings } = userSettings;
    storages.local.set({ userSettings: restSettings });
  }
};
