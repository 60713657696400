import { Modal } from "antd";
import React, { useState } from "react";
import { GetPhrase } from "../../hocs/WithTranslation";
import { CustomInput } from "../CustomInput/CustomInput";

interface FiltersGroupModalProps {
  isModalVisible: boolean;
  handleConfirm: (value: string) => void;
  handleCancel: () => void;
  translate: GetPhrase;
  phrases?: { name?: string; giveFilterSetName?: string };
}

export const FiltersGroupModal: React.FC<FiltersGroupModalProps> = ({
  isModalVisible,
  handleCancel,
  handleConfirm,
  translate,
  phrases,
}) => {
  const [input, setInput] = useState("");
  return (
    <Modal
      visible={isModalVisible}
      onOk={() => handleConfirm(input)}
      onCancel={handleCancel}
    >
      <p>{translate(phrases?.giveFilterSetName ?? "")}</p>
      <CustomInput
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={translate(phrases?.name ?? "")}
      />
    </Modal>
  );
};
