import {
  action,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import { debounce } from "../../helpers/helpers";

export interface ITableFilters<FiltersType = any> {
  filters: FiltersType;
  setFilter: (key: string | number | symbol, value: any) => any;
  setFilters: (filters: FiltersType) => any;
}

export default class TableFilters<FiltersType>
  implements ITableFilters<FiltersType>
{
  filters: FiltersType = {} as FiltersType;
  selectedFiltersGroup?: string;
  filterReactionsDisposer?: IReactionDisposer;
  handleApplyFilters;
  constructor(callback: (...args: any[]) => any, timeout: number) {
    makeObservable(this, {
      filters: observable.ref,
      selectedFiltersGroup: observable,
      setFilter: action.bound,
      setFilters: action.bound,
      clearFilters: action.bound,
      setSelectedFiltersGroup: action.bound,
    });
    this.handleApplyFilters = debounce(
      this.applyFilters.bind(this)(callback),
      timeout
    );
  }

  private applyFilters(callback: (...args: any[]) => any) {
    return (...args: any[]) => {
      callback(...args);
    };
  }

  activateFiltersReaction(): void {
    this.filterReactionsDisposer = reaction(
      () => this.filters,
      (filters) => {
        const { offset, limit, ...restFilters } = filters as {
          [key: string]: any;
        };
        this.handleApplyFilters(restFilters);
      }
    );
  }

  disposeFiltersReaction(): void {
    this.filterReactionsDisposer?.();
    this.filterReactionsDisposer = undefined;
  }

  setFilter(key: string | number | symbol, value: any): void {
    this.filters = { ...this.filters, [key]: value };
  }

  setFilters(filters: FiltersType) {
    this.filters = filters;
  }

  clearFilters() {
    this.filters = {} as FiltersType;
  }

  setSelectedFiltersGroup(value?: string) {
    this.selectedFiltersGroup = value;
  }
}
