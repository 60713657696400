import { Modal, Space, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { CustomSelect } from "../CustomSelect/CustomSelect";
import { TranslationPropsType } from "../../hocs/WithTranslation";
import { IChangeStatusModal } from "../../interfaces/ChangeStatusModal";
import "./ChangeStatusesModal.less";

export interface ChangeStatusesModalProps<T> {
  model: T;
  translation: TranslationPropsType;
  getDataStatus?: () => boolean;
  phrases?: {
    status?: string;
    setStatus?: string;
    serialOperiations?: string;
    chooseStatus?: string;
  };
}

export type StatusChangeResponse = {
  type: string;
  textToTranslate: string;
  additionalInfo?: any;
};

export const ChangeStatusesModal = observer(
  <T extends IChangeStatusModal>({
    model,
    translation,
    getDataStatus,
    phrases,
  }: ChangeStatusesModalProps<T>) => {
    const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
      undefined
    );
    const { translate, currentLang } = translation;
    const handleCancel = useCallback(
      () => model.setIsChangeStatusesModalVisible(false),
      []
    );
    const handleOk = useCallback(async () => {
      const responses: StatusChangeResponse[] =
        await model.handleMassStatusesChange(selectedStatus);
      responses?.forEach(({ type, textToTranslate, additionalInfo }) => {
        const messageText = translate(textToTranslate) + (additionalInfo ?? "");
        type === "error"
          ? message.error(messageText)
          : message.success(messageText);
      });
    }, [selectedStatus, currentLang]);

    return (
      <Modal
        title={`${translate(phrases?.serialOperiations ?? "")} - ${translate(
          phrases?.setStatus ?? ""
        )}`}
        visible={model.isChangeStatusesModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={getDataStatus?.()}
      >
        <Space>
          {translate(phrases?.chooseStatus ?? "")}
          <CustomSelect
            isInsideModal
            className="modal-statuses-select"
            showSearch
            placeholder={translate(phrases?.status ?? "")}
            value={selectedStatus}
            options={model.getStatusesSelectOptions()}
            onChange={setSelectedStatus}
          />
        </Space>
      </Modal>
    );
  }
);
