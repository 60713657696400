import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import { BaseViewModel } from "../BaseViewModel";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import "./PageRenderer.less";

export const PageRenderer: FC<{
  model: BaseViewModel;
  loaderSelector: string;
}> = observer(({ model, children, loaderSelector }) => (
  <>
    {model.isInitialized
      ? children
      : createPortal(
          <div className="page-loader">
            <LoadingIndicator size={48} />
          </div>,
          document.querySelector(loaderSelector) as Element
        )}
  </>
));
