enum StorageType {
  SessionStorage = "sessionStorage",
  LocalStorage = "localStorage"
}

export enum LocalStorageKeys {
  activeUser = "activeUser",
  userSettings = "userSettings",
  users = "users"
}

type Instances = { [StorageType.LocalStorage]: Storage; [StorageType.SessionStorage]: Storage };

interface IStorage {
  get: <T>(key: string) => T | undefined;
  set: (obj: { [key: string]: any }) => void;
  remove: (key: string) => boolean;
  clear: () => void;
  modify: (obj: { [key: string]: any }) => void;
}

class Storage implements IStorage {
  static _instances: Instances = {} as Instances;
  private readonly storage?: globalThis.Storage;

  constructor(type: StorageType.LocalStorage | StorageType.SessionStorage) {
    this.storage = globalThis[type];
    if (Storage._instances[type]) {
      return Storage._instances[type];
    }
    Storage._instances[type] = this;
  }

  get<T = any>(key: string) {
    const stringValue = this.storage?.getItem(key);
    if (stringValue) {
      return JSON.parse(stringValue);
    }
  }

  set(obj: { [key: string]: any }) {
    Object.entries(obj).forEach(([key, value]) => this.storage?.setItem(key, JSON.stringify(value)));
  }

  modify(obj: { [key: string]: any }) {
    Object.entries(obj).forEach(([key, value]) => {
      const store = this.get(key);
      const target = this.overwrite(store, value);
      this.set({ [key]: target });
    });
  }

  remove(key: string) {
    const removedValue = this.get(key);
    this.storage?.removeItem(key);
    return !!removedValue;
  }

  clear() {
    this.storage?.clear();
  }

  private overwrite(target: { [key: string]: any }, value: any) {
    if (this.isObject(value)) {
      Object.entries(value).forEach(([k, v]) => {
        target[k] = this.isObject(target[k]) && this.isObject(v);
        if (this.isObject(target[k]) && this.isObject(v)) {
          this.overwrite(target[k], v);
        } else {
          target[k] = v;
        }
      });
    }
    return target;
  }

  private isObject(value: any) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }
}

const storages = { local: new Storage(StorageType.LocalStorage), session: new Storage(StorageType.SessionStorage) };

export default storages;
