import React, { useCallback } from "react";
import { Space, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { GetPhrase } from "../../../hocs/WithTranslation";
import { ITableFilters } from "../../../tools/TableFilters/TableFilters";
import { isEmptyObject } from "../../../helpers/helpers";

interface CompactFilterSectionProps {
  model: ITableFilters;
  translate: GetPhrase;
  filterLabels: { [key: string]: string };
}

const omitKeys = Object.freeze(["limit", "offset", "sortby", "category"]);

export const CompactFilterSection: React.FC<CompactFilterSectionProps> =
  observer(({ model, translate, filterLabels }) => {
    const onTagClose = useCallback(
      (key: string) => model.setFilter(key, undefined),
      []
    );
    return (
      <Space className="filters-container">
        {Object.entries(model.filters)
          .filter(([key]) => !omitKeys.includes(key))
          .map(([key, value]) => (
            <>
              {value && !isEmptyObject(value) && (
                <Tag key={key} closable onClose={() => onTagClose(key)}>
                  {`${translate(filterLabels[key])}: ${value}`}
                </Tag>
              )}
            </>
          ))}
      </Space>
    );
  });
