import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { InternalLoadingIndicator } from "shared-modules";
import "./App.css";
import AppRouter from "./routers/AppRouterComponent";

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
