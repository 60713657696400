import { computed, makeObservable } from "mobx";
import { DataStatuses, IBaseApi } from "./BaseApi";

export interface ModelI {
  dataStatuses: DataStatuses;
}

export abstract class Model<P = any, T = { [key: string]: any }>
  implements ModelI
{
  constructor(
    public title: string = "",
    protected readonly api: IBaseApi<P> & T
  ) {
    makeObservable(this, {
      dataStatuses: computed,
    });
  }

  get dataStatuses() {
    return this.api.dataStatuses;
  }
}
