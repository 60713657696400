import { IComponentWithParams } from "../core/ModuleBuilder/ModuleBuilder.types";
import { AccessControl } from "../core/RouterProvider/RouterProvider.types";
import { TPageComponent } from "../core/types";

type TModel = {
  new (): Record<string, unknown>;
};

export type Options = {
  route?: { path: string; exact?: boolean };
  Model?: TModel;
  accessControl?: AccessControl;
  [key: string]: any;
};

type TBindParams = (
  Page: TPageComponent,
  params: Options
) => IComponentWithParams;

const bindParams: TBindParams = (Page, params) => {
  Object.assign(Page, params);
  return Page as IComponentWithParams;
};

export default bindParams;
