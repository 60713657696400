// import { setAxiosClientAuthHeaders, setAxiosClientBaseUrl } from "../../services/api/axios-client";
import {
  GetTokenSilentlyOptions,
  GetIdTokenClaimsOptions,
  IdToken,
} from "@auth0/auth0-spa-js";

export type Tokens = { idToken: string; accessToken: string };

export async function getTokens(options: {
  getToken: (options?: GetTokenSilentlyOptions) => Promise<string>;
  getClaim: (options?: GetIdTokenClaimsOptions) => Promise<IdToken>;
  setAxiosClientBaseUrl?: (organizationName: string) => any;
  setAxiosClientAuthHeaders?: (accessToken: string, idToken: string) => any;
  refresh?: boolean;
}): Promise<Tokens | undefined> {
  try {
    const accessToken = await options.getToken({
      ignoreCache: !!options.refresh,
    });
    const { __raw: idToken } = await options.getClaim();
    options.setAxiosClientAuthHeaders?.(accessToken, idToken);

    return { accessToken, idToken };
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

export function loadCurrentOrganization(
  organizationName?: string,
  setAxiosClientBaseUrl?: (organizationName: string) => any
) {
  if (organizationName) {
    setAxiosClientBaseUrl?.(organizationName);
  }
}
