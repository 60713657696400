import { AxiosResponse } from "axios";
import { action, computed, makeObservable, observable } from "mobx";

const __noop = async <T>(): Promise<Response<T>> =>
  new Promise((resolve) => resolve(undefined));

export enum DATA_STATUSES {
  INIT,
  GETTING_DATA,
  GETTING_QUANTITY,
  DATA_LOADED,
  QUANTITY_LOADED,
}

export enum MODIFY_STATUSES {
  INIT,
  ADDING_DATA,
  EDITING_DATA,
  DELETING_DATA,
  DATA_LOADED,
}

export type Response<T> = Promise<AxiosResponse<T> | undefined>;

export type DataStatuses = {
  isLoading: boolean;
  isLoaded: boolean;
  isLoadingQuantity: boolean;
  isQuantityLoaded: boolean;
  isInit: boolean;
  isAdding: boolean;
  isEditing: boolean;
  isDeleting: boolean;
  isModified: boolean;
};

export interface IBaseApi<P> {
  dataStatus: DATA_STATUSES;
  modifyStatus: MODIFY_STATUSES;
  dataStatuses: DataStatuses;

  getAll<T>(params?: P): Response<T>;
  add<T, D = P>(params?: D): Response<T>;
  edit<T, D = P>(id: number, params?: D): Response<T>;
  delete<T>(id: number): Response<T>;
  getQuantity: () => Response<number>;
  get<T, D = any>(id: number, params?: D): Response<T>;
}

export default abstract class BaseApi<P> implements IBaseApi<P> {
  dataStatus = DATA_STATUSES.INIT;
  modifyStatus = MODIFY_STATUSES.INIT;

  constructor(protected readonly path: string) {
    makeObservable(this, {
      dataStatus: observable,
      modifyStatus: observable,
      _setDataStatus: action.bound,
      _setModifyStatus: action.bound,
      dataStatuses: computed,
    });
  }

  async get<T, P = any>(id: number, params?: P) {
    this._setDataStatus(DATA_STATUSES.GETTING_DATA);
    const response = await this._get<T>(id, params);
    this._setDataStatus(DATA_STATUSES.DATA_LOADED);
    return response;
  }

  async getAll<T, P>(params?: P) {
    this._setDataStatus(DATA_STATUSES.GETTING_DATA);
    const response = await this._getAll<T>(params as P | any);
    this._setDataStatus(DATA_STATUSES.DATA_LOADED);
    return response;
  }

  async add<T, D = P>(params: D) {
    this._setModifyStatus(MODIFY_STATUSES.ADDING_DATA);
    const response = await this._add<T>(params as P | any);
    this._setModifyStatus(MODIFY_STATUSES.DATA_LOADED);
    return response;
  }

  async edit<T, D = P>(id: number, params: D) {
    this._setModifyStatus(MODIFY_STATUSES.EDITING_DATA);
    const response = await this._edit<T>(id, params as P | any);
    this._setModifyStatus(MODIFY_STATUSES.DATA_LOADED);
    return response;
  }

  async delete<T>(id: number) {
    this._setModifyStatus(MODIFY_STATUSES.DELETING_DATA);
    const response = await this._delete<T>(id);
    this._setModifyStatus(MODIFY_STATUSES.DATA_LOADED);
    return response;
  }

  async getQuantity() {
    this._setDataStatus(DATA_STATUSES.GETTING_QUANTITY);
    const response = await this._getQuantity();
    this._setDataStatus(DATA_STATUSES.DATA_LOADED);
    return response;
  }

  _setDataStatus(dataStatus: DATA_STATUSES): void {
    this.dataStatus = dataStatus;
  }

  _setModifyStatus(modifyStatus: MODIFY_STATUSES): void {
    this.modifyStatus = modifyStatus;
  }

  get dataStatuses() {
    return {
      isLoading: this.dataStatus === DATA_STATUSES.GETTING_DATA,
      isLoaded: this.dataStatus === DATA_STATUSES.DATA_LOADED,
      isLoadingQuantity: this.dataStatus === DATA_STATUSES.GETTING_QUANTITY,
      isQuantityLoaded: this.dataStatus === DATA_STATUSES.QUANTITY_LOADED,
      isInit: this.dataStatus === DATA_STATUSES.INIT,
      isAdding: this.modifyStatus === MODIFY_STATUSES.ADDING_DATA,
      isEditing: this.modifyStatus === MODIFY_STATUSES.EDITING_DATA,
      isDeleting: this.modifyStatus === MODIFY_STATUSES.DELETING_DATA,
      isModified: this.modifyStatus === MODIFY_STATUSES.DATA_LOADED,
    };
  }

  protected async _get<T, P = any>(
    id: number,
    params?: P
  ): Promise<Response<T>> {
    return await __noop<T>();
  }

  protected async _getAll<T>(params?: P): Promise<Response<T>> {
    return await __noop<T>();
  }

  protected async _add<T, D = P>(params?: D): Promise<Response<T>> {
    return await __noop<T>();
  }

  protected async _edit<T, D = P>(
    id: number,
    params?: D
  ): Promise<Response<T>> {
    return await __noop<T>();
  }

  protected async _delete<T>(id: number): Promise<Response<T>> {
    return await __noop<T>();
  }

  protected async _getQuantity(): Promise<Response<number>> {
    return await __noop<number>();
  }

  protected getPath(...pathParts: Array<string | number>) {
    return `${this.path}/${pathParts?.join("/")}`;
  }
}
