import { RadioChangeEvent, Space } from "antd";
import { observer } from "mobx-react-lite";
import { Moment } from "moment";
import React, { ChangeEvent, FC, useCallback } from "react";
import { GetPhrase } from "../../../hocs/WithTranslation";
import { ITableFilters } from "../../../tools/TableFilters/TableFilters";
import { CustomDatePicker } from "../../CustomDatePicker/CustomDatePicker";
import { CustomInput } from "../../CustomInput/CustomInput";
import { CustomSelect, OptionType } from "../../CustomSelect/CustomSelect";
import { CustomSwitch, SwitchOption } from "../../CustomSwitch/CustomSwitch";

export type FilterObject<T> = {
  type: FilterTypes;
  filterApiName: keyof T;
  className?: string;
  options?: OptionType[];
  switchOptions?: SwitchOption[];
  customLabel?: string;
  customPlaceholder?: string;
  mode?: "multiple" | "tags";
};

export enum FilterTypes {
  select,
  input,
  date,
  switch,
}

interface ExpandedFiltersSectionProps {
  model: ITableFilters;
  translate: GetPhrase;
  getFilters: (model: any, translate?: GetPhrase) => any[];
  filterLabels: { [key: string]: string };
}

export const ExpandedFiltersSection: FC<ExpandedFiltersSectionProps> = observer(
  ({ model, translate, getFilters, filterLabels }) => {
    const getFilterComponent = useCallback(
      (filter: FilterObject<any>) => {
        const onSelectChange = (value: string) =>
          model.setFilter(filter.filterApiName, value);
        const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
          model.setFilter(filter.filterApiName, e.target.value);
        const onSwitchChange = (e: RadioChangeEvent) =>
          model.setFilter(filter.filterApiName, e.target.value);
        const onDateChange = (_: Moment | null, value: string) =>
          model.setFilter(filter.filterApiName, value);

        switch (filter.type) {
          case FilterTypes.select:
            return (
              <CustomSelect
                className={filter.className}
                showSearch
                placeholder={translate(
                  filter.customPlaceholder ||
                    filterLabels[filter.filterApiName as string]
                )}
                value={model.filters[filter.filterApiName] as string}
                options={filter.options || []}
                onChange={onSelectChange}
                mode={filter.mode}
                allowClear
                translate={translate}
              />
            );
          case FilterTypes.switch:
            return (
              <CustomSwitch
                label={
                  translate(filterLabels[filter.filterApiName as string]) + ":"
                }
                value={model.filters[filter.filterApiName] || false}
                options={filter.switchOptions || []}
                onChange={onSwitchChange}
                translate={translate}
              />
            );
          case FilterTypes.input:
            return (
              <CustomInput
                className={filter.className}
                label={translate(filter.customLabel as string)}
                placeholder={translate(
                  filter.customPlaceholder ||
                    filterLabels[filter.filterApiName as string]
                )}
                onChange={onInputChange}
                value={model.filters[filter.filterApiName] as string}
                allowClear
              />
            );
          case FilterTypes.date:
            return (
              <CustomDatePicker
                className="date-picker"
                value={model.filters[filter.filterApiName] as string}
                onChange={onDateChange}
                label={translate(
                  filter.customLabel ||
                    filterLabels[filter.filterApiName as string]
                )}
                allowClear
              />
            );

          default:
            return null;
        }
      },
      [model.filters]
    );
    return (
      <Space size="middle" className="filters-container">
        {getFilters(model, translate).map(getFilterComponent)}
      </Space>
    );
  }
);
