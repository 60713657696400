import React, { RefObject, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Button } from "antd";
import { GetPhrase } from "../../hocs/WithTranslation";
import "./FilterDropdown.less";

export type ConfirmType = ({
  closeDropdown,
}?: {
  closeDropdown: boolean;
}) => void;

export type FilterModelType<T = any> = {
  setSearchText: (text: string) => any;
  setSearchedColumn: (index: DataIndexType<T>) => any;
};

export type DataIndexType<T> = keyof T;

export interface FilterDropdownProps<T = { [key: string]: any }> {
  model: FilterModelType<T>;
  prefixCls: string;
  dataIndex: any;
  dataName: string;
  setSelectedKeys: (selectedKeys: string[]) => void;
  selectedKeys: string[];
  confirm: ConfirmType;
  clearFilters: () => void;
  inputRef: RefObject<Input>;
  translate: GetPhrase;
  phrases?: { lookup?: string; search?: string; filter?: string };
}

function _FilterDropdown<T>({
  model,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  dataName,
  inputRef,
  translate,
  phrases,
}: FilterDropdownProps<T>) {
  const { setSearchText, setSearchedColumn } = model;

  const handleSearch = useCallback(
    (
      selectedKeys: string[],
      confirm: ConfirmType,
      dataIndex: DataIndexType<T>
    ) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    },
    [setSearchedColumn, setSearchText]
  );

  const handleReset = useCallback(
    (clearFilters: () => void) => {
      clearFilters();
      setSearchText("");
    },
    [setSearchText]
  );

  const onFilterClick = useCallback(() => {
    confirm({ closeDropdown: false });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, [confirm, dataIndex, selectedKeys, setSearchText, setSearchedColumn]);

  return (
    <div className="filter-section-container">
      <Input
        ref={inputRef}
        placeholder={`${translate(phrases?.lookup ?? "")} ${translate(
          dataName
        )}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
        >
          {translate(phrases?.search ?? "")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button type="link" size="small" onClick={onFilterClick}>
          {translate(phrases?.filter ?? "")}
        </Button>
      </Space>
    </div>
  );
}

export const FilterDropdown = observer(_FilterDropdown);
