import React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import { GetPhrase } from "../../hocs/WithTranslation";
import { InternalLoadingIndicator } from "../InternalLoadingIndicator/InternalLoadingIndicator";

interface ConfirmDeleteModalProps {
  isModalVisible: boolean;
  checkIsLoading?: () => boolean;
  handleConfirm: () => Promise<void>;
  handleCancel: () => void;
  translate: GetPhrase;
  phrases?: { confirmation?: string; deleteQuestion?: string };
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = observer(
  ({
    isModalVisible,
    handleConfirm,
    checkIsLoading,
    handleCancel,
    translate,
    phrases,
  }) => {
    return (
      <Modal
        title={translate(phrases?.confirmation ?? "")}
        visible={isModalVisible}
        onOk={handleConfirm}
        confirmLoading={checkIsLoading?.()}
        onCancel={handleCancel}
      >
        <InternalLoadingIndicator visible={checkIsLoading?.()} />
        {translate(phrases?.deleteQuestion ?? "")}
      </Modal>
    );
  }
);
