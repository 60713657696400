import React, { FC } from "react";
import { Route, Switch, RouteProps, Redirect } from "react-router-dom";

type NenstedRouteType = FC<RouteProps>;

const NestedRoute: NenstedRouteType = ({ children, path, exact }) => {
  return (
    <Route path={path} exact>
      {children}
    </Route>
  );
};

export const NestedRouter: FC<{ basename: string; location?: any }> & { Route: NenstedRouteType } = ({
  children,
  basename
}) => {
  return (
    <Switch>
      {children}
      <Route>
        <Redirect to={basename} />
      </Route>
    </Switch>
  );
};

NestedRouter.Route = NestedRoute;
