import { ComponentType } from "react";
import { RedirectProps } from "react-router";
import { IwithModel, withModel } from "../../../hocs/WithModel";
import { ModuleBuilder } from "../../ModuleBuilder/ModuleBuilder";
import { IComponentWithParams } from "../../ModuleBuilder/ModuleBuilder.types";
import RouterProvider from "../../RouterProvider/RouterProvider";
import {
  ExtendsRouteProps,
  IRouterProvider,
} from "../../RouterProvider/RouterProvider.types";

const connector = withModel as IwithModel<IComponentWithParams>;

const appRouterProvider = () => {
  let _provider: IRouterProvider | any;

  return {
    init(
      routeComponent: ComponentType<ExtendsRouteProps>,
      privateRouteComponent: ComponentType<ExtendsRouteProps>,
      redirectTo: string
    ) {
      _provider = new RouterProvider(
        routeComponent,
        privateRouteComponent,
        redirectTo
      );
      return this;
    },
    build(pages: { [pageName: string]: ComponentType }) {
      if (_provider) {
        const buildPage = _buildPageFactory(_provider);
        Object.values(pages)?.forEach(buildPage);
      }

      return _provider;
    },
    get() {
      return _provider;
    },
  };
};

const _buildPageFactory = (provider: any) => (page: any) => {
  const { model } = page;
  return new ModuleBuilder({
    model,
    component: page,
    routerProvider: provider,
    connector,
  })
    .subscribe()
    .connectModel(undefined, "#app-loader")
    .localize()
    .addToRouter()
    .build();
};

export default appRouterProvider();
