import { message } from "antd";

export const isObject = (value: any) =>
  typeof value === "object" && value !== null && !Array.isArray(value);

export const isNotNullish = (value: any) =>
  value !== undefined && value !== null;

export const isEmptyObject = (value: any) =>
  typeof value === "object" && Object.keys(value).length === 0;

export const isPromise = (promise: any) =>
  !!promise && typeof promise.then === "function";

export const debounce = (func: (...args: any[]) => any, timeout: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(func, args), timeout);
  };
};

export const createActionHandler =
  (options: {
    action?: (params?: any, addintinal?: any) => Promise<boolean>;
    messages: { success: string; fail: string };
    isResponseRequired?: boolean;
  }) =>
  async () => {
    if (!options.action) {
      return;
    }
    const success = await options.action?.();
    success
      ? message.success(options.messages.success)
      : message.error(options.messages.fail);
    if (options.isResponseRequired) return success;
  };

export const removeTagsInsideTag = (
  text: string,
  tags: readonly { tagToRemove: string; tagToLookInside: string }[]
) => {
  let previousText;
  let newText = text;
  tags.forEach((tag) => {
    const regExp = new RegExp(
      // eslint-disable-next-line
      `(<${tag.tagToLookInside}>.*?)<${tag.tagToRemove}>(.*?)<\/${tag.tagToRemove}>+?(.*?<\/${tag.tagToLookInside}>)`,
      "g"
    );
    do {
      previousText = newText;
      newText = newText.replace(regExp, `$1$2$3`);
    } while (newText !== previousText);
  });
  return newText;
};
