import React, { FC, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import {
  DollarCircleOutlined,
  LineOutlined,
  SmileOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import "./MaiaStatus.less";

enum MAIA_STATUSES {
  returnRisk,
  regularCustomer,
  highValue,
}

type MaiaStatusProps = {
  status: MAIA_STATUSES;
  phrases?: {
    returnRisk?: string;
    regularCustomer?: string;
    highValue?: string;
  };
};

const MaiaStatus: FC<MaiaStatusProps> = observer(({ status, phrases }) => {
  const { t } = useTranslation();
  const { returnRisk, regularCustomer, highValue } = phrases;
  const render = (icon: ReactNode, title: string, color?: string) => (
    <Tooltip title={title} color={color}>
      {icon}
    </Tooltip>
  );
  const selectStatus = () => {
    switch (status) {
      case MAIA_STATUSES.returnRisk:
        return render(
          <WarningOutlined className="maia-return-risk" />,
          t(returnRisk ?? "")
        );
      case MAIA_STATUSES.regularCustomer:
        return render(
          <SmileOutlined className="maia-regular-customer" />,
          t(regularCustomer ?? "")
        );
      case MAIA_STATUSES.highValue:
        return render(
          <DollarCircleOutlined className="maia-high-value" />,
          t(highValue ?? "")
        );
      default:
        return <LineOutlined className="maia-default" />;
    }
  };
  return selectStatus();
});

export const Maia = {
  Component: MaiaStatus,
  statuses: MAIA_STATUSES,
  quantity: Object.values(MAIA_STATUSES).filter((v) => typeof v === "number")
    .length,
};
