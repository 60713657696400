import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Radio, RadioChangeEvent, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GetPhrase } from "../../hocs/WithTranslation";
import { ICustomFiltersLists } from "../../interfaces/CustomFiltersLists";
import { isEmptyObject } from "../../helpers/helpers";
import "./CustomFiltersTopBar.less";
import { FiltersGroupModal } from "../FiltersGroupModal/FiltersGroupModal";
import { FiltersGroupsActionsDropdown } from "../FiltersGroupsActionsDropdown/FiltersGroupsActionsDropdown";

interface FiltersTopBarProps<T, D> {
  model: D;
  translate: GetPhrase;
  currentLang: string;
  deleteFiltersGroup: (key?: string) => void;
  setFiltersGroup: (key: string, filterts: T) => void;
  getQuantity: (
    params: T,
    noSave?: boolean
  ) => string | number | Promise<number | string>;
  children: ReactNode;
  phrases?: {
    successSaved?: string;
    emptyNameError?: string;
    saveView?: string;
    deleteView?: string;
    noFiltersSelectedError?: string;
  };
}

export const CustomFiltersTopBar = observer(
  <T, D extends ICustomFiltersLists<T>>({
    model,
    translate,
    currentLang,
    children,
    deleteFiltersGroup,
    setFiltersGroup,
    getQuantity,
    phrases,
  }: FiltersTopBarProps<T, D>) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filtersItemsQuantities, setFiltersItemsQuantities] = useState<{
      [key: string]: string;
    }>({});

    useEffect(() => {
      async function getFiltersQuantities() {
        const itemsQuantities: { [key: string]: string } = {};
        const filtersArray = Object.keys(model.filtersList || []);
        const filtersQuantitiesPromises = filtersArray.map(async (key) => {
          return (itemsQuantities[key] = String(
            await getQuantity(model.filtersList[key], true)
          ));
        });
        await Promise.all(filtersQuantitiesPromises);
        setFiltersItemsQuantities(itemsQuantities);
      }
      getFiltersQuantities();
    }, []);

    const radioButtonsOptions = useMemo(() => {
      const filtersArray = Object.keys(model.filtersList || []);
      const savedFiltersOptions = filtersArray.map((key) => ({
        label: key,
        value: key,
      }));
      return savedFiltersOptions.map((filter) => {
        return {
          ...filter,
          label: filtersItemsQuantities?.[filter.label]
            ? `${translate(filter.label)} (${
                filtersItemsQuantities?.[filter.label]
              })`
            : translate(filter.label),
        };
      });
    }, [model.filtersList, filtersItemsQuantities, currentLang]);

    const handleCancel = useCallback(() => setIsModalVisible(false), []);
    const handleConfirm = useCallback(
      (inputValue) => {
        if (inputValue) {
          setFiltersGroup(inputValue, model.filters);
          setIsModalVisible(false);
          message.success(translate(phrases?.successSaved ?? ""));
        } else {
          message.error(translate(phrases?.emptyNameError ?? ""));
        }
      },
      [setFiltersGroup]
    );

    const onRadioGroupChange = useCallback(
      (e: RadioChangeEvent) => {
        model.setFilters(model.filtersList[e.target.value]);
        model.setSelectedFiltersGroup(e.target.value);
      },
      [model.filtersList]
    );

    phrases?.successSaved ?? "";

    const onSaveButtonPress = useCallback(() => {
      if (isEmptyObject(model.filters)) {
        message.error(translate(phrases?.noFiltersSelectedError ?? ""));
      } else {
        setIsModalVisible(true);
      }
    }, [model.filters]);

    const onDeleteFilterGroupPress = useCallback(
      () => deleteFiltersGroup(model.selectedFiltersGroup),
      [model.selectedFiltersGroup, deleteFiltersGroup]
    );

    const filtersViewsActions = useMemo(
      () => [
        {
          label: phrases?.saveView ?? "",
          icon: <PlusOutlined className="save-filters-icon" />,
          onClick: onSaveButtonPress,
        },
        {
          label: phrases?.deleteView ?? "",
          icon: <DeleteOutlined className="delete-filters-icon" />,
          onClick: onDeleteFilterGroupPress,
        },
      ],
      [onSaveButtonPress, onDeleteFilterGroupPress]
    );

    return (
      <>
        <Space
          size="middle"
          className="filters-topbar-wrapper"
          direction="vertical"
        >
          <Space className="filters-topbar-container" size="middle">
            <Space className="filters-groups-section" size="middle">
              <FiltersGroupsActionsDropdown
                currentLang={currentLang}
                translate={translate}
                actions={filtersViewsActions}
              />
              <Radio.Group
                value={model.selectedFiltersGroup}
                onChange={onRadioGroupChange}
                options={radioButtonsOptions}
                optionType="button"
              />
            </Space>
          </Space>
          {children}
        </Space>
        <FiltersGroupModal
          translate={translate}
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      </>
    );
  }
);
