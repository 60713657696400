import React, { FC, ReactNode, useEffect, useMemo, useCallback } from "react";
import { createPortal } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import useAuth from "../../../hooks/useAuth";
import { getUserSettings } from "../../../helpers/localStorage";
import { loadCurrentOrganization } from "../../../helpers/auth";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import "./Auth.less";

type AuthProps = {
  render: (authData: {
    user: any;
    isAuthenticated: boolean;
    isLoading: boolean;
  }) => ReactNode;
  fallback?: ReactNode;
  mode?: "vanilla" | "spa";
  loaderSelector: string;
  redirectionPathname?: string;
  className?: string;
};

export const Auth: FC<AuthProps> = function _Auth({
  render,
  fallback,
  loaderSelector,
  mode = "spa",
  redirectionPathname,
  className,
}) {
  const history = useHistory<{ fromRedirection: boolean; pathname: string }>();
  const pathname = useLocation<any>().pathname;

  const { isAuthenticated, isLoading, user } = useAuth();

  const { name: organizationName } =
    getUserSettings()?.selectedOrganization ?? {};

  const canRedirect =
    redirectionPathname &&
    pathname !== redirectionPathname &&
    isAuthenticated &&
    !organizationName;

  const redirect = useCallback(
    () =>
      history.push(redirectionPathname, { fromRedirection: true, pathname }),
    [history, pathname, redirectionPathname]
  );

  useEffect(() => {
    canRedirect ? redirect() : loadCurrentOrganization(organizationName);
  }, [redirect, canRedirect, organizationName]);

  const loadingIndicator = useMemo(
    () => (
      <div
        className={classNames(
          {
            "auth-loading-indicator-wrapper": !className,
          },
          className
        )}
      >
        <LoadingIndicator size={96} />
      </div>
    ),
    []
  );

  return (
    <>
      {mode === "vanilla" || !isLoading
        ? render({ user, isAuthenticated, isLoading })
        : createPortal(
            fallback ?? loadingIndicator,
            document.querySelector(loaderSelector) as Element
          )}
    </>
  );
};

export default observer(Auth);
