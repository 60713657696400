import { RefObject, useEffect, useRef } from "react";

const useOnClickOutside = <T extends HTMLElement = any>(config: {
  isActive?: boolean;
  onClickOutside: () => any;
  excludedSelectors?: string[];
}): RefObject<T> => {
  const editableNode = useRef<T>(null);
  useEffect(() => {
    if (!config.isActive) {
      return;
    }
    const listener = (e: MouseEvent | TouchEvent) => {
      !e.composedPath().includes(editableNode.current as EventTarget) &&
        !config.excludedSelectors?.includes((e.target as Element).className) &&
        config.onClickOutside();
    };

    document.body.addEventListener(`click`, listener, { capture: true });
    document.body.addEventListener("touchstart", listener, { capture: true });

    return () => {
      document.body.removeEventListener(`click`, listener, { capture: true });
      document.body.removeEventListener(`touchstart`, listener, {
        capture: true,
      });
    };
  }, [config.isActive]);

  return editableNode;
};

export default useOnClickOutside;
