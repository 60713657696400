import { ColumnsState } from "@ant-design/pro-table";
import { action, makeObservable, observable } from "mobx";
import { BaseViewModel } from "../core/BaseViewModel";
import { GetPhrase } from "../hocs/WithTranslation";
import { LOCAL_STORAGE_KEYS } from "../tools/LocalStorageSynchronizer/localStorageKeys";
import { LocalStorageSynchronizer } from "../tools/LocalStorageSynchronizer/LocalStorageSynchronizer";

const { TABLE_COLUMNS } = LOCAL_STORAGE_KEYS;

export type TableColumnsType = {
  [key: string]: Record<string, ColumnsState>;
};

export type DataScope = { limit: number; offset: number | null };

export default abstract class TableDataModel<T = any> extends BaseViewModel {
  searchText = "";
  searchedColumn = "";
  dataScope: { limit: number; offset: number | null } = {
    offset: null,
    limit: 10,
  };
  tableColumns: { [key: string]: TableColumnsType } = {};
  localStorage: LocalStorageSynchronizer;
  constructor(title: string, cacheKeys: string[] = []) {
    super(title);
    makeObservable(this, {
      searchText: observable,
      searchedColumn: observable,
      dataScope: observable,
      tableColumns: observable.ref,
      setTableColumns: action.bound,
      setSearchText: action.bound,
      setSearchedColumn: action.bound,
      setDataScope: action.bound,
    });
    this.localStorage = new LocalStorageSynchronizer(this, [
      TABLE_COLUMNS,
      ...cacheKeys,
    ] as string[]);
  }
  setTableColumns(tableColumns: TableColumnsType): void {
    this.tableColumns = { [this.title]: tableColumns };
  }

  setSearchText(value: string): void {
    this.searchText = value;
  }

  setSearchedColumn(value: string | number | symbol): any {
    this.searchedColumn = value as string;
  }

  setDataScope(scope: { limit?: number | null; offset?: number | null }): void {
    if (scope.limit) {
      this.dataScope.limit = scope.limit;
    }
    if (scope.offset !== undefined) {
      this.dataScope.offset = scope.offset;
    }
  }

  abstract mapTableData(
    arg?: GetPhrase | ({ translate?: GetPhrase } & { [key: string]: any })
  ): T[];
}
